import React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useUser } from '../../modules/amplify';
import { createEntry } from '../../services/entries';
import { Stages } from '../../services/stages';
import * as Common from '../../components/Styles/Common.styles';
import * as Styles from './CreateEntry.styles';

interface CreateEntryProps {
  stage: Stages;
}

const CreateEntry: React.FC<CreateEntryProps> = props => {
  const { stage } = props;
  const [message, setMessage] = React.useState('');
  const { id: conversationId = '' } = useParams();
  const user = useUser();
  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setMessage(event.target.value);
  }, []);
  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      createEntry(message, user.email, conversationId, stage).then(() => setMessage(''));
    },
    [conversationId, message, stage, user.id],
  );

  return (
    <Common.Root>
      <form onSubmit={handleSubmit}>
        <Common.GridContainer alignItems="center" spacing={1}>
          <Common.GridItem md={11} xs={12}>
            <Styles.TextField fullWidth value={message} onChange={handleChange} />
          </Common.GridItem>
          <Common.GridItem md={1} textAlign="right" xs={12}>
            <Button fullWidth color="primary" size="large" type="submit" variant="contained">
              Enviar
            </Button>
          </Common.GridItem>
        </Common.GridContainer>
      </form>
    </Common.Root>
  );
};

export default CreateEntry;
