import {
  Avatar as AvatarBase,
  AvatarProps as AvatarBaseProps,
  Chip as ChipBase,
  ChipProps as ChipBaseProps,
  Dialog as DialogBase,
  styled,
} from '@mui/material';
import * as Common from '../components/Styles/Common.styles';

export const Root = styled(Common.Root)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

export const DialogConversation = styled(DialogBase)`
  & .MuiDialogContent-root {
    padding: ${props => props.theme.spacing(2)};
    max-height: 400px;
  }
  & .MuiDialogActions-root {
    padding: ${props => props.theme.spacing(2)};
  }
`;

interface AvatarProps extends AvatarBaseProps {
  bgColor?: React.CSSProperties['color'];
}

export const Avatar = styled(({ bgColor, ...props }: AvatarProps) => <AvatarBase {...props} />)`
  background-color: ${props => props.bgColor};
  font-size: 10px;
  height: 24px;
  width: 24px;
`;

interface ChipProps extends ChipBaseProps {
  bgColor?: React.CSSProperties['color'];
}

export const Chip = styled(({ bgColor, ...props }: ChipProps) => <ChipBase {...props} />)`
  background-color: ${props => props.bgColor};
`;

export const Footer = styled(Common.GridContainer)`
  &.MuiGrid-container {
    background-color: ${props => props.theme.palette['gi-secondary'].main};
  }
`;
