import cookies from 'js-cookie';
import { REACT_APP_API_BASE_URL } from '../config/environment';
import { ACCESS_TOKEN_COOKIE_KEY } from '../modules/amplify/auth';
import { Person, PersonHome } from './people';
import { BucketFile } from './files';
import { Project } from './projects';

export interface Conversation {
  id: string;
  title: string;
  description: string;
  beginDate: string;
  endDate: string;
  creator: string;
  files: BucketFile[];
  stageId: string;
  participants: Person[];
  projectId: string;
}

export interface ConversationHome {
  id: string;
  title: string;
  description: string;
  beginDate: string;
  endDate: string;
  creator: Person | undefined;
  stageId: string;
  participants: PersonHome[];
  project: Project | undefined;
}

export const getConversations = async (): Promise<ConversationHome[]> => {
  const response = await fetch(`${REACT_APP_API_BASE_URL}/conversations`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get(ACCESS_TOKEN_COOKIE_KEY) ?? ''}`,
    },
  });
  return await response.json();
};

export const setConversation = async (conversation: Conversation): Promise<Conversation> => {
  const response = await fetch(`${REACT_APP_API_BASE_URL}/conversations`, {
    method: 'POST',
    body: JSON.stringify(conversation),
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      charset: 'utf-8',
      Authorization: `Bearer ${cookies.get(ACCESS_TOKEN_COOKIE_KEY) ?? ''}`,
    },
  });
  return await response.json();
};

export const getConversationByStage = async (conversationId: string, stageId: string): Promise<Conversation> => {
  const response = await fetch(`${REACT_APP_API_BASE_URL}/conversations/${conversationId}/${stageId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get(ACCESS_TOKEN_COOKIE_KEY) ?? ''}`,
    },
  });
  return await response.json();
};

export const updateConversation = async (conversation: Conversation): Promise<Conversation> => {
  const response = await fetch(`${REACT_APP_API_BASE_URL}/conversations`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get(ACCESS_TOKEN_COOKIE_KEY) ?? ''}`,
    },
    body: JSON.stringify(conversation),
  });
  return await response.json();
};

export interface ConversationTitle {
  id: string;
  title: string;
}

export const updateTitleConversation = async (conversation: ConversationTitle): Promise<ConversationTitle> => {
  const response = await fetch(`${REACT_APP_API_BASE_URL}/conversations/title`, {
    method: 'PUT',
    body: JSON.stringify(conversation),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get(ACCESS_TOKEN_COOKIE_KEY) ?? ''}`,
    },
  });
  return await response.json();
};

export interface ConversationProject {
  id: string;
  projectId: string;
}

export const updateProjectConversation = async (conversation: ConversationProject): Promise<ConversationProject> => {
  const response = await fetch(`${REACT_APP_API_BASE_URL}/conversations/project`, {
    method: 'PUT',
    body: JSON.stringify(conversation),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get(ACCESS_TOKEN_COOKIE_KEY) ?? ''}`,
    },
  });
  return await response.json();
};

export interface ConversationDescription {
  id: string;
  description: string;
}

export const updateDescriptionConversation = async (
  conversation: ConversationDescription,
): Promise<ConversationDescription> => {
  const response = await fetch(`${REACT_APP_API_BASE_URL}/conversations/description`, {
    method: 'PUT',
    body: JSON.stringify(conversation),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get(ACCESS_TOKEN_COOKIE_KEY) ?? ''}`,
    },
  });
  return await response.json();
};

export interface ConversationParticipants {
  id: string;
  participants: Person[];
  stageId: string;
}

export const updateParticipantsConversation = async (
  conversation: ConversationParticipants,
): Promise<ConversationParticipants> => {
  const response = await fetch(`${REACT_APP_API_BASE_URL}/conversations/participants`, {
    method: 'PUT',
    body: JSON.stringify(conversation),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get(ACCESS_TOKEN_COOKIE_KEY) ?? ''}`,
    },
  });
  return await response.json();
};

export const bytesToSize = (bytes: number): string => {
  const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i: number = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
};
