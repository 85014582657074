import React, { useCallback, useState } from 'react';
import { Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDropzone } from 'react-dropzone';
import { Dropzone } from './FileUpload.styles';
import { BucketFile, getFiles, uploadFile } from '../../services/files';
import { createConversationEvent, Event, getConversationEventSubscription } from '../../services/conversationEvent';
import DialogError from '../../components/DialogError/DialogError';
interface UploadProps {
  s3KeyPrefix: string;
  files: BucketFile[];
  userId: string;
  conversationId: string;
  stageId: string;
  setFiles: (value: BucketFile[]) => void;
}
const Upload: React.FC<UploadProps> = ({ s3KeyPrefix, files, setFiles, userId, conversationId, stageId }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [openDialogError, setOpenDialogError] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop: useCallback((acceptedFiles: File[]) => {
      setSelectedFile(acceptedFiles[0]);
    }, []),
  });
  const handleFileUpload = useCallback((): void => {
    if (selectedFile == null) {
      return;
    }
    setIsLoading(true);
    uploadFile(s3KeyPrefix, selectedFile)
      .then((newBucketFile: BucketFile) => {
        setFiles([...files, newBucketFile]);
        setSelectedFile(null);
        createConversationEvent(Event.files, userId, conversationId, stageId);
        setIsLoading(false);
      })
      .catch(() => {
        setOpenDialogError(true);
        setMessageError('No fue posible agregar el archivo seleccionado');
        setIsLoading(false);
      });
  }, [selectedFile, s3KeyPrefix, setFiles, files, userId, conversationId, stageId]);

  const handleRemoveFile = useCallback(() => {
    setSelectedFile(null);
  }, []);
  React.useEffect(() => {
    const subscription = getConversationEventSubscription(conversationId, stageId, response => {
      if (userId !== response?.author) {
        if (response?.type === Event.files) {
          getFiles(conversationId)
            .then((response: BucketFile[]) => setFiles(response))
            .catch(() => {
              setOpenDialogError(true);
              setMessageError('No se pudieron obtener los archivos asociados a la conversación');
            });
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [conversationId, setFiles, stageId, userId]);

  return (
    <>
      <Dropzone {...getRootProps()} isDragActive={isDragActive}>
        <input {...getInputProps()} />
        {selectedFile != null ? (
          <>
            <Typography variant="h6" style={{ margin: '16px 0' }}>
              {selectedFile.name}
            </Typography>
            <IconButton onClick={handleRemoveFile}>
              <Close />
            </IconButton>
          </>
        ) : (
          <>
            <CloudUploadIcon />
            <Typography variant="body1" style={{ margin: '16px 0' }}>
              Arrastre y suelte el archivo aquí o haga clic para seleccionar un archivo
            </Typography>
          </>
        )}
      </Dropzone>
      <Button
        variant="contained"
        color="primary"
        style={{ margin: '16px 0', alignSelf: 'center' }}
        disabled={selectedFile == null || isLoading}
        onClick={handleFileUpload}
      >
        {isLoading ? (
          <>
            <CircularProgress size={20} style={{ marginRight: '8px' }} />
            Cargando...
          </>
        ) : (
          'Subir'
        )}
      </Button>
      <DialogError openDialog={openDialogError} setOpenDialog={setOpenDialogError} message={messageError} />
    </>
  );
};

export default Upload;
