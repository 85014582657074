import React from 'react';
import { Auth } from 'aws-amplify';
import cookie from 'js-cookie';
import {
  REACT_APP_AWS_COGNITO_DOMAIN,
  REACT_APP_AWS_COGNITO_REDIRECT_URI,
  REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
} from '../../config/environment';

export const ACCESS_TOKEN_COOKIE_KEY = 'access_token';
const EMAIL_COOKIE_KEY = 'email';
const ID_COOKIE_KEY = 'id';
const NAME_COOKIE_KEY = 'name';
const GROUPS_COOKIE_KEY = 'groups';

export const INITIAL_UNAUTHENTICATED_ERROR = 'The user is not authenticated';

type UseLoginHook = () => () => void;

export const useLogin: UseLoginHook = () =>
  React.useCallback(() => {
    const query = new window.URLSearchParams({
      client_id: REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
      redirect_uri: REACT_APP_AWS_COGNITO_REDIRECT_URI,
      response_type: 'CODE',
      scope: 'aws.cognito.signin.user.admin email openid profile',
    });
    window.location.href = `https://${REACT_APP_AWS_COGNITO_DOMAIN}/oauth2/authorize?${query.toString()}`;
  }, []);

type UseLogoutHook = () => (event: React.FormEvent<HTMLFormElement>) => Promise<any>;

export const useLogout: UseLogoutHook = () =>
  React.useCallback(event => {
    event?.preventDefault();
    cookie.remove(ACCESS_TOKEN_COOKIE_KEY);
    cookie.remove(EMAIL_COOKIE_KEY);
    cookie.remove(GROUPS_COOKIE_KEY);
    cookie.remove(ID_COOKIE_KEY);
    cookie.remove(NAME_COOKIE_KEY);

    return Auth.signOut();
  }, []);

interface Session {
  accessToken: string;
  email: string;
  id: string;
  name: string;
  groups: string[];
}

type ErrorCallback = (error: Error | string) => void;
type SuccessCallback = (session: Session) => void;
type UseSessionHook = (successCallback: SuccessCallback, errorCallback: ErrorCallback) => void;

export const useSession: UseSessionHook = (successCallback, errorCallback) => {
  const firstTime = React.useRef(true);
  const handleUnload = React.useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault();
    cookie.remove(ACCESS_TOKEN_COOKIE_KEY);
    cookie.remove(EMAIL_COOKIE_KEY);
    cookie.remove(GROUPS_COOKIE_KEY);
    cookie.remove(ID_COOKIE_KEY);
    cookie.remove(NAME_COOKIE_KEY);
  }, []);

  React.useEffect(() => {
    if (!firstTime.current) {
      // return;
    }

    firstTime.current = false;

    window.addEventListener('beforeunload', handleUnload);
    Auth.currentSession()
      .then(userAccess => {
        const token = userAccess.getAccessToken();
        const groups = userAccess.getIdToken()?.payload['cognito:groups'];
        const userId = userAccess.getIdToken()?.payload?.sub;
        const accessToken = token.getJwtToken();
        const attributesDictionary: Record<string, string> = userAccess.getIdToken().payload;

        const session: Session = {
          accessToken,
          groups,
          email: attributesDictionary.email,
          id: userId,
          name: `${attributesDictionary.given_name} ${attributesDictionary.family_name}`,
        };
        cookie.set(ACCESS_TOKEN_COOKIE_KEY, session.accessToken);
        cookie.set(EMAIL_COOKIE_KEY, session.email);
        cookie.set(GROUPS_COOKIE_KEY, session.groups.join(','));
        cookie.set(ID_COOKIE_KEY, session.id);
        cookie.set(NAME_COOKIE_KEY, session.name);
        return successCallback(session);
      })
      .catch(error => errorCallback(error));
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

type TUseUser = (dependencies?: unknown[]) => Session;

export const useUser: TUseUser = (dependencies = []) =>
  React.useMemo<Session>(
    () => ({
      accessToken: cookie.get(ACCESS_TOKEN_COOKIE_KEY) ?? '',
      email: cookie.get(EMAIL_COOKIE_KEY) ?? '',
      groups: cookie.get(GROUPS_COOKIE_KEY)?.split(',') ?? [],
      id: cookie.get(ID_COOKIE_KEY) ?? '',
      name: cookie.get(NAME_COOKIE_KEY) ?? '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...dependencies],
  );
