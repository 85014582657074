/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEntry = /* GraphQL */ `
  mutation CreateEntry(
    $input: CreateEntryInput!
    $condition: ModelEntryConditionInput
  ) {
    createEntry(input: $input, condition: $condition) {
      id
      author
      conversation
      message
      stage
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const updateEntry = /* GraphQL */ `
  mutation UpdateEntry(
    $input: UpdateEntryInput!
    $condition: ModelEntryConditionInput
  ) {
    updateEntry(input: $input, condition: $condition) {
      id
      author
      conversation
      message
      stage
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const deleteEntry = /* GraphQL */ `
  mutation DeleteEntry(
    $input: DeleteEntryInput!
    $condition: ModelEntryConditionInput
  ) {
    deleteEntry(input: $input, condition: $condition) {
      id
      author
      conversation
      message
      stage
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      author
      conversation
      content
      stage
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      author
      conversation
      content
      stage
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      author
      conversation
      content
      stage
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const createConversationEvent = /* GraphQL */ `
  mutation CreateConversationEvent(
    $input: CreateConversationEventInput!
    $condition: ModelConversationEventConditionInput
  ) {
    createConversationEvent(input: $input, condition: $condition) {
      author
      conversation
      type
      stage
      createdAt
      id
      updatedAt
    }
  }
`;
export const updateConversationEvent = /* GraphQL */ `
  mutation UpdateConversationEvent(
    $input: UpdateConversationEventInput!
    $condition: ModelConversationEventConditionInput
  ) {
    updateConversationEvent(input: $input, condition: $condition) {
      author
      conversation
      type
      stage
      createdAt
      id
      updatedAt
    }
  }
`;
export const deleteConversationEvent = /* GraphQL */ `
  mutation DeleteConversationEvent(
    $input: DeleteConversationEventInput!
    $condition: ModelConversationEventConditionInput
  ) {
    deleteConversationEvent(input: $input, condition: $condition) {
      author
      conversation
      type
      stage
      createdAt
      id
      updatedAt
    }
  }
`;
export const createConversationEventFinished = /* GraphQL */ `
  mutation CreateConversationEventFinished(
    $input: CreateConversationEventFinishedInput!
    $condition: ModelConversationEventFinishedConditionInput
  ) {
    createConversationEventFinished(input: $input, condition: $condition) {
      author
      conversation
      type
      stage
      createdAt
      id
      updatedAt
    }
  }
`;
export const updateConversationEventFinished = /* GraphQL */ `
  mutation UpdateConversationEventFinished(
    $input: UpdateConversationEventFinishedInput!
    $condition: ModelConversationEventFinishedConditionInput
  ) {
    updateConversationEventFinished(input: $input, condition: $condition) {
      author
      conversation
      type
      stage
      createdAt
      id
      updatedAt
    }
  }
`;
export const deleteConversationEventFinished = /* GraphQL */ `
  mutation DeleteConversationEventFinished(
    $input: DeleteConversationEventFinishedInput!
    $condition: ModelConversationEventFinishedConditionInput
  ) {
    deleteConversationEventFinished(input: $input, condition: $condition) {
      author
      conversation
      type
      stage
      createdAt
      id
      updatedAt
    }
  }
`;
