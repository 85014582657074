/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateEntry = /* GraphQL */ `
  subscription OnCreateEntry($filter: ModelSubscriptionEntryFilterInput) {
    onCreateEntry(filter: $filter) {
      id
      author
      conversation
      message
      stage
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEntry = /* GraphQL */ `
  subscription OnUpdateEntry($filter: ModelSubscriptionEntryFilterInput) {
    onUpdateEntry(filter: $filter) {
      id
      author
      conversation
      message
      stage
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEntry = /* GraphQL */ `
  subscription OnDeleteEntry($filter: ModelSubscriptionEntryFilterInput) {
    onDeleteEntry(filter: $filter) {
      id
      author
      conversation
      message
      stage
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDocument = /* GraphQL */ `
  subscription OnCreateDocument($filter: ModelSubscriptionDocumentFilterInput) {
    onCreateDocument(filter: $filter) {
      id
      author
      conversation
      content
      stage
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDocument = /* GraphQL */ `
  subscription OnUpdateDocument($filter: ModelSubscriptionDocumentFilterInput) {
    onUpdateDocument(filter: $filter) {
      id
      author
      conversation
      content
      stage
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDocument = /* GraphQL */ `
  subscription OnDeleteDocument($filter: ModelSubscriptionDocumentFilterInput) {
    onDeleteDocument(filter: $filter) {
      id
      author
      conversation
      content
      stage
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConversationEvent = /* GraphQL */ `
  subscription OnCreateConversationEvent(
    $filter: ModelSubscriptionConversationEventFilterInput
  ) {
    onCreateConversationEvent(filter: $filter) {
      author
      conversation
      type
      stage
      createdAt
      id
      updatedAt
    }
  }
`;
export const onUpdateConversationEvent = /* GraphQL */ `
  subscription OnUpdateConversationEvent(
    $filter: ModelSubscriptionConversationEventFilterInput
  ) {
    onUpdateConversationEvent(filter: $filter) {
      author
      conversation
      type
      stage
      createdAt
      id
      updatedAt
    }
  }
`;
export const onDeleteConversationEvent = /* GraphQL */ `
  subscription OnDeleteConversationEvent(
    $filter: ModelSubscriptionConversationEventFilterInput
  ) {
    onDeleteConversationEvent(filter: $filter) {
      author
      conversation
      type
      stage
      createdAt
      id
      updatedAt
    }
  }
`;
export const onCreateConversationEventFinished = /* GraphQL */ `
  subscription OnCreateConversationEventFinished(
    $filter: ModelSubscriptionConversationEventFinishedFilterInput
  ) {
    onCreateConversationEventFinished(filter: $filter) {
      author
      conversation
      type
      stage
      createdAt
      id
      updatedAt
    }
  }
`;
export const onUpdateConversationEventFinished = /* GraphQL */ `
  subscription OnUpdateConversationEventFinished(
    $filter: ModelSubscriptionConversationEventFinishedFilterInput
  ) {
    onUpdateConversationEventFinished(filter: $filter) {
      author
      conversation
      type
      stage
      createdAt
      id
      updatedAt
    }
  }
`;
export const onDeleteConversationEventFinished = /* GraphQL */ `
  subscription OnDeleteConversationEventFinished(
    $filter: ModelSubscriptionConversationEventFinishedFilterInput
  ) {
    onDeleteConversationEventFinished(filter: $filter) {
      author
      conversation
      type
      stage
      createdAt
      id
      updatedAt
    }
  }
`;
