import { styled, Dialog as DialogBase } from '@mui/material';
import * as Common from '../../components/Styles/Common.styles';

export const Root = styled(Common.Root)`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
`;

export const Label = styled('p')<{ active?: boolean }>`
  color: ${props =>
    props.active ?? false ? props.theme.palette['gi-primary'].main : props.theme.palette['gi-secondary'].main};
`;

export const DialogConversation = styled(DialogBase)`
  & .MuiDialogContent-root {
    padding: ${props => props.theme.spacing(2)};
    max-height: 400px;
  }
  & .MuiDialogActions-root {
    padding: ${props => props.theme.spacing(2)};
  }
`;
