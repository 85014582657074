import { styled } from '@mui/system';

export const Dropzone = styled('div', {
  shouldForwardProp: prop => prop !== 'isDragActive',
})<{ isDragActive: boolean }>(({ isDragActive }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '32px 0',
  borderWidth: '2px',
  borderRadius: '2px',
  borderColor: isDragActive ? 'blue' : '#ddd',
  borderStyle: 'dashed',
  backgroundColor: '#f9f9f9',
  color: '#444',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  minHeight: 200,
  justifyContent: 'center',
}));
