import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CardContent, CircularProgress, Typography } from '@mui/material';
import routes from '../config/routes';
import { useLogin, useSession } from '../modules/amplify';
import * as Common from '../components/Styles/Common.styles';
import * as Styles from './Login.styles';

const Login: React.FC = () => {
  const [loading] = React.useState(true);
  const [error] = React.useState<string>('');
  const navigate = useNavigate();
  const handleSubmit = useLogin();
  useSession(
    () => navigate(routes.home, { replace: true }),
    () => {
      handleSubmit();
    },
  );

  return (
    <Styles.Root>
      {loading && (
        <Common.GridContainer alignItems="center" height="100%">
          <Common.GridItem textAlign="center" xs={12}>
            <CircularProgress color="gi-primary" />
          </Common.GridItem>
        </Common.GridContainer>
      )}
      {!loading && (
        <Common.GridContainer alignItems="center" height="100%">
          <Common.GridItem md={4} xs={12} />
          <Common.GridItem container justifyContent="center" md={4} xs={12}>
            <Styles.Card>
              <Styles.CardMedia height="190px" image="/assets/images/logo.png" padding="5px 2px" />
              <CardContent>
                <form onSubmit={handleSubmit}>
                  <Common.GridContainer>
                    <Common.GridItem margin="20px 0 0" xs={12}>
                      <Styles.Button color="gi-primary" size="large" type="submit" variant="outlined">
                        login
                      </Styles.Button>
                    </Common.GridItem>
                  </Common.GridContainer>
                  {error !== '' && (
                    <Common.GridContainer>
                      <Common.GridItem margin="10px 0 0" xs={12}>
                        <Typography color="error">{error}</Typography>
                      </Common.GridItem>
                    </Common.GridContainer>
                  )}
                </form>
              </CardContent>
            </Styles.Card>
          </Common.GridItem>
          <Common.GridItem md={4} xs={12} />
        </Common.GridContainer>
      )}
    </Styles.Root>
  );
};

export default Login;
