import React from 'react';
import { Grid as GridBase, GridProps, styled } from '@mui/material';

export const Root = styled('div')`
  height: 100%;
  width: 100%;
`;

interface IGridProps extends GridProps {
  height?: React.CSSProperties['height'];
  margin?: React.CSSProperties['margin'];
  padding?: React.CSSProperties['padding'];
}

export const GridContainer = styled((props: IGridProps) => <GridBase container {...props} />)`
  &.MuiGrid-container {
    height: ${props => props.height};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`;

export const GridItem = styled((props: IGridProps) => <GridBase item {...props} />)`
  &.MuiGrid-item {
    height: ${props => props.height};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`;
