import React from 'react';
import { TinyMCE } from 'tinymce';
import { Editor as EditorBase } from '@tinymce/tinymce-react';
import { EditorRef } from './Editor.hook';

export { default as useEditor } from './Editor.hook';
export type { EditorRef } from './Editor.hook';

declare global {
  interface Window {
    tinymce?: TinyMCE;
  }
}

interface EditorProps {
  deactivateEditor: boolean;
  onChange: (editor: EditorRef, content: string) => void;
  onLoad: (editor: EditorRef) => void;
  onClick: () => void;
  onBlur: () => void;
}

const Editor: React.ForwardRefRenderFunction<EditorRef, EditorProps> = (props, ref) => {
  const { deactivateEditor, onChange, onLoad, onClick, onBlur } = props;
  const [ready, setReady] = React.useState(false);
  // FIXME: Needed because a weird behavior of the ForwardRe type,
  // does not recognize the variable as a ref and does not contain the "current" property.
  const updatedRef = ref as React.MutableRefObject<EditorRef>;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = React.useCallback(() => onClick(), []);
  const handleBlur = React.useCallback(() => onBlur(), []);
  const handleChange = React.useCallback((content = '') => onChange(updatedRef.current, content), [onChange]);
  const handleInit = React.useCallback((_event: unknown, instance: EditorBase['editor']) => {
    updatedRef.current = {
      instance,
      api: window.tinymce,
    };

    setReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!ready) {
      return;
    }

    onLoad(updatedRef?.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready]);

  return (
    <EditorBase
      init={{
        content_style: 'body { font-family: Helvetica, Arial,sans-serif; font-size:14px }',
        height: 500,
        menubar: false,
        plugins: [
          'advlist',
          'anchor',
          'autolink',
          'charmap',
          'code',
          'fullscreen',
          'help',
          'image',
          'insertdatetime',
          'link',
          'lists',
          'media',
          'preview',
          'searchreplace',
          'table',
          'visualblocks',
          'wordcount',
        ],
        toolbar: `
          undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help 
        `,
      }}
      tinymceScriptSrc="/assets/js/tinymce/tinymce.min.js"
      onEditorChange={handleChange}
      onInit={handleInit}
      onClick={handleClick}
      onBlur={handleBlur}
      disabled={deactivateEditor}
    />
  );
};

export default React.forwardRef(Editor);
