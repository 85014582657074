import React from 'react';
import { Box, CircularProgress, Fab, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { InsertDriveFile } from '@mui/icons-material';
import { bytesToSize } from '../../services/conversations';
import { BucketFile, getSignedURL } from '../../services/files';
import DialogError from '../../components/DialogError/DialogError';
interface FileListProps {
  s3KeyPrefix: string;
  files: BucketFile[];
}

const FileList: React.FC<FileListProps> = ({ s3KeyPrefix, files }) => {
  const [loading, setLoading] = React.useState('');
  const [openDialogError, setOpenDialogError] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');

  const onDownload = (keyFile: string): void => {
    setLoading(keyFile);
    getSignedURL(keyFile)
      .then(blobResult => {
        const url = URL.createObjectURL(blobResult);
        const link = document.createElement('a');
        link.href = url;
        link.download = keyFile.replace(`${s3KeyPrefix}/`, '');
        link.click();
        setLoading('');
      })
      .catch(() => {
        setOpenDialogError(true);
        setMessageError('No fue posible descargar el archivo seleccionado');
      });
  };
  return (
    <>
      {files.length > 0 ? (
        <List sx={{ height: '300px', overflow: 'auto' }}>
          {files.map((file: BucketFile, index: number) => (
            <ListItem sx={{ color: 'black' }} key={index}>
              <ListItemIcon>
                <InsertDriveFile />
              </ListItemIcon>
              <ListItemText
                primary={file.key.replace(`${s3KeyPrefix}/`, '')}
                secondary={<Typography variant="caption">{bytesToSize(file.size)}</Typography>}
              />
              <ListItemIcon>
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Fab aria-label="save" color="primary" onClick={() => onDownload(file.key)}>
                    <CloudDownloadIcon />
                  </Fab>
                  {loading === file.key && (
                    <CircularProgress
                      size={68}
                      sx={{
                        position: 'absolute',
                        top: -6,
                        left: -6,
                        zIndex: 1,
                      }}
                    />
                  )}
                </Box>
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography>No se encontraron archivos</Typography>
      )}
      <DialogError openDialog={openDialogError} setOpenDialog={setOpenDialogError} message={messageError} />
    </>
  );
};

export default FileList;
