import React from 'react';

const NotFound: React.FC = () => {
  return (
    <div>
      <h1>404</h1>
      <h2>Not Found</h2>
    </div>
  );
};

export default NotFound;
