export const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? '';
export const REACT_APP_AWS_APP_SYNC_ENDPOINT = process.env.REACT_APP_AWS_APP_SYNC_ENDPOINT ?? '';
export const REACT_APP_AWS_COGNITO_DOMAIN = process.env.REACT_APP_AWS_COGNITO_DOMAIN ?? '';
export const REACT_APP_AWS_COGNITO_IDENTITY_PROVIDER = process.env.REACT_APP_AWS_COGNITO_IDENTITY_PROVIDER ?? '';
export const REACT_APP_AWS_COGNITO_REDIRECT_URI = process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI ?? '';
export const REACT_APP_AWS_COGNITO_USER_POOL_ID = process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID ?? '';
export const REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID =
  process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID ?? '';
export const REACT_APP_AWS_REGION = process.env.REACT_APP_AWS_REGION ?? '';
export const REACT_APP_AWS_S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET ?? '';
export const REACT_AWS_COGNITO_IDENTITY_POOL_ID = process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID ?? '';
export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL ?? '';
export const REACT_APP_GOOGLE_RE_CAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RE_CAPTCHA_KEY ?? '';

export const isDevEnv = process.env.REACT_APP_ENVIRONMENT !== 'prod';
