const routes = {
  context: '/conversation/:id/context',
  declaration: '/conversation/:id/declaration',
  evaluation: '/conversation/:id/evaluation',
  home: '/home',
  login: '/login',
  negotiation: '/conversation/:id/negotiation',
  notFound: '*',
  realization: '/conversation/:id/realization',
  root: '/',
};

export default routes;
