import React from 'react';
import { TinyMCE } from 'tinymce';
import { Editor as EditorBase } from '@tinymce/tinymce-react';

export interface EditorRef {
  api?: TinyMCE;
  instance?: EditorBase['editor'];
}

type UseEditorHook = () => {
  ref: React.MutableRefObject<EditorRef>;
  updateContent: (content: string) => void;
};

const useEditor: UseEditorHook = () => {
  const ref = React.useRef<EditorRef>({});
  const updateContent = React.useCallback((content = '') => {
    const element = (ref.current.instance?.getBody() ?? {}) as HTMLBodyElement;

    // Is done it this way to avoid triggering change events every time
    // the content is updated from an external source.
    element.innerHTML = content;
  }, []);

  return { ref, updateContent };
};

export default useEditor;
