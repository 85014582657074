import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import { Entry, getEntries, getEntriesSubscription } from '../../services/entries';
import { Stages } from '../../services/stages';
import { Person } from '../../services/people';
import * as Styles from './EntriesList.styles';
import * as Common from '../../components/Styles/Common.styles';

interface EntriesListProps {
  stage: Stages;
  people: Person[];
}

const EntriesList: React.FC<EntriesListProps> = props => {
  const { stage, people } = props;
  const [entries, setEntries] = React.useState<Entry[]>([]);
  const { id: conversationId = '' } = useParams();

  React.useEffect(() => {
    const getData = (): void => {
      getEntries(conversationId, stage).then(response => {
        setEntries(response);
      });
    };
    const subscription = getEntriesSubscription(conversationId, stage, () => getData());

    getData();

    return () => {
      subscription.unsubscribe();
    };
  }, [conversationId, stage]);

  return (
    <Common.Root>
      <Common.GridContainer padding="0 0">
        <Common.GridItem md={12} xs={12}>
          <Card>
            <CardContent sx={{ height: '300px', overflow: 'auto' }}>
              {entries.map(entry => (
                <div key={entry.id}>
                  <Common.GridContainer>
                    <Common.GridItem md={1} xs={3}>
                      <Styles.Avatar>
                        {people.find(person => person.email === entry.author)?.abbreviation ?? 'ANON'}
                      </Styles.Avatar>
                    </Common.GridItem>
                    <Common.GridItem md={11} xs={9}>
                      <p style={{ color: 'navy', marginBottom: 0 }}>
                        {`${people.find(person => person.email === entry.author)?.name ?? 'Anónimo'}  
                        `}
                      </p>
                      <p style={{ color: 'grey', fontSize: 12, marginTop: 1 }}>
                        {new Date(entry.createdAt).toLocaleString('es-CL')}
                      </p>
                      <p style={{ inlineSize: '100%', overflowWrap: 'break-word' }}>{`${entry.message}`}</p>
                    </Common.GridItem>
                  </Common.GridContainer>
                </div>
              ))}
            </CardContent>
          </Card>
        </Common.GridItem>
      </Common.GridContainer>
    </Common.Root>
  );
};

export default EntriesList;
