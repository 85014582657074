import React from 'react';
import {
  Button as ButtonBase,
  Card as CardBase,
  CardMedia as CardMediaBase,
  CardMediaProps,
  FormControl as FormControlBase,
  styled,
} from '@mui/material';
import * as Common from '../components/Styles/Common.styles';

export const Root = styled(Common.Root)`
  background-color: ${props => props.theme.palette['gi-secondary'].main};
`;

export const Card = styled(CardBase)`
  &.MuiCard-root {
    min-width: 334px;
    width: 334px;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    &.MuiCard-root {
      width: 100%;
    }
  }
`;

interface ICardMediaProps extends CardMediaProps {
  height?: React.CSSProperties['height'];
  padding?: React.CSSProperties['padding'];
}

export const CardMedia = styled(CardMediaBase)<ICardMediaProps>`
  &.MuiCardMedia-root {
    background-size: contain;
    box-sizing: border-box;
    height: ${props => props.height};
    padding: ${props => props.padding};
  }
`;

export const FormControl = styled(FormControlBase)`
  &.MuiFormControl-root {
    margin: ${props => props.margin};
    width: 100%;
  }
`;

export const Button = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: 100%;
  }
`;
