import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { AWSAppSyncRealTimeProvider } from '@aws-amplify/pubsub';
import Observable, { ZenObservable } from 'zen-observable-ts';
import { Entry, queries, mutations, subscriptions } from '../modules/amplify';
import { Stages } from './stages';

export type { Entry } from '../modules/amplify';

export const ENTRY_STAGES = {
  [Stages.context]: '1',
  [Stages.negotiation]: '2',
  [Stages.realization]: '3',
  [Stages.evaluation]: '4',
  [Stages.declaration]: '5',
};

interface GetEntriesData {
  entriesByCreatedAt: {
    items: Entry[];
    nextToken: null;
  };
}
type GetEntriesService = (conversationId: string, stage: Stages) => Promise<Entry[]>;

export const getEntries: GetEntriesService = (conversationId, stage) => {
  const filter = {
    stage: {
      eq: ENTRY_STAGES[stage],
    },
  };
  const operation = {
    query: queries.entriesByCreatedAt,
    variables: {
      filter,
      conversation: conversationId,
      sortDirection: 'ASC',
    },
  };

  return (API.graphql(operation) as Promise<GraphQLResult<GetEntriesData>>).then(
    response => response.data?.entriesByCreatedAt?.items ?? [],
  );
};

type EntriesSubscriptionCallback = () => void;
type EntriesSubscription = Observable<{
  provider: AWSAppSyncRealTimeProvider;
  value: GraphQLResult<Entry>;
}>;
type GetEntriesSubscription = (
  conversationId: string,
  stage: Stages,
  callback: EntriesSubscriptionCallback,
) => ZenObservable.Subscription;

export const getEntriesSubscription: GetEntriesSubscription = (conversationId, stage, callback) => {
  const operation = graphqlOperation(subscriptions.onCreateEntry, {
    filter: {
      conversation: {
        eq: conversationId,
      },
      stage: {
        eq: ENTRY_STAGES[stage],
      },
    },
  });
  const subscription = (API.graphql(operation) as unknown as EntriesSubscription).subscribe({
    error: (error: Error) => {
      throw error;
    },
    next: () => callback(),
  });

  return subscription;
};

interface CreateEntryData {
  createEntry: Entry;
}
type CreateEntryService = (
  message: string,
  authorId: string,
  conversationId: string,
  stage: Stages,
) => Promise<Entry | null>;

export const createEntry: CreateEntryService = (message, authorId, conversationId, stage) => {
  const input = {
    message,
    author: authorId,
    conversation: conversationId,
    stage: ENTRY_STAGES[stage],
  };
  const operation = {
    query: mutations.createEntry,
    variables: {
      input,
    },
  };

  return (API.graphql(operation) as Promise<GraphQLResult<CreateEntryData>>).then(
    response => response.data?.createEntry ?? null,
  );
};

interface DeleteEntryData {
  deleteEntry: Entry;
}
type DeleteEntryService = (id: string) => Promise<Entry | null>;

export const deleteEntry: DeleteEntryService = id => {
  const input = {
    id,
  };
  const operation = {
    query: mutations.deleteEntry,
    variables: {
      input,
    },
  };

  return (API.graphql(operation) as Promise<GraphQLResult<DeleteEntryData>>).then(
    response => response.data?.deleteEntry ?? null,
  );
};
