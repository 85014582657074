/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEntry = /* GraphQL */ `
  query GetEntry($id: ID!) {
    getEntry(id: $id) {
      id
      author
      conversation
      message
      stage
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const listEntries = /* GraphQL */ `
  query ListEntries(
    $filter: ModelEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        conversation
        message
        stage
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      author
      conversation
      content
      stage
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        conversation
        content
        stage
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConversationEvent = /* GraphQL */ `
  query GetConversationEvent($id: ID!) {
    getConversationEvent(id: $id) {
      author
      conversation
      type
      stage
      createdAt
      id
      updatedAt
    }
  }
`;
export const listConversationEvents = /* GraphQL */ `
  query ListConversationEvents(
    $filter: ModelConversationEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversationEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        author
        conversation
        type
        stage
        createdAt
        id
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConversationEventFinished = /* GraphQL */ `
  query GetConversationEventFinished($id: ID!) {
    getConversationEventFinished(id: $id) {
      author
      conversation
      type
      stage
      createdAt
      id
      updatedAt
    }
  }
`;
export const listConversationEventFinisheds = /* GraphQL */ `
  query ListConversationEventFinisheds(
    $filter: ModelConversationEventFinishedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversationEventFinisheds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        author
        conversation
        type
        stage
        createdAt
        id
        updatedAt
      }
      nextToken
    }
  }
`;
export const entriesByCreatedAt = /* GraphQL */ `
  query EntriesByCreatedAt(
    $conversation: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entriesByCreatedAt(
      conversation: $conversation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        author
        conversation
        message
        stage
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const documentsByCreatedAt = /* GraphQL */ `
  query DocumentsByCreatedAt(
    $conversation: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentsByCreatedAt(
      conversation: $conversation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        author
        conversation
        content
        stage
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const conversationEventByCreatedAt = /* GraphQL */ `
  query ConversationEventByCreatedAt(
    $conversation: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConversationEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationEventByCreatedAt(
      conversation: $conversation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        author
        conversation
        type
        stage
        createdAt
        id
        updatedAt
      }
      nextToken
    }
  }
`;
export const conversationEventFinishedByCreatedAt = /* GraphQL */ `
  query ConversationEventFinishedByCreatedAt(
    $conversation: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConversationEventFinishedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationEventFinishedByCreatedAt(
      conversation: $conversation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        author
        conversation
        type
        stage
        createdAt
        id
        updatedAt
      }
      nextToken
    }
  }
`;
