import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  Step,
  StepLabel,
  TextField,
  Typography,
} from '@mui/material';
import { getPeople, Person } from '../../services/people';
import { getRoles, Role } from '../../services/roles';
import { getStages, Stage } from '../../services/stages';
import { setConversation, updateConversation, Conversation } from '../../services/conversations';
import { createDocument } from '../../services/documents';
import DialogError from '../../components/DialogError/DialogError';
import ParticipantsList from '../ParticipantsList/ParticipantsList.container';
import * as Common from '../../components/Styles/Common.styles';
import * as Styles from './CreateConversation.styles';
import { useUser } from '../../modules/amplify';
import routes from '../../config/routes';

const steps = ['Detalles', 'Personas involucradas'];

interface CreateConversationProps {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
}

const CreateConversation: React.FC<CreateConversationProps> = props => {
  const { openDialog, setOpenDialog } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [roles, setRoles] = React.useState<Role[]>([]);
  const [people, setPeople] = React.useState<Person[]>([]);
  const [openDialogError, setOpenDialogError] = React.useState(false);
  const [disabledFinish, setDisabledFinish] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  const [stages, setStages] = React.useState<Stage[]>([]);
  const [initStagesId, setInitStagesId] = React.useState('');
  const [defaultRoleId, setDefaultRoleId] = React.useState('');
  const user = useUser();
  const navigate = useNavigate();
  const handleCloseDialog = React.useCallback((): void => {
    setOpenDialog(false);
    setActiveStep(0);
    setTitle('');
    setDescription('');
    setPeople(people.map(person => ({ ...person, roleId: defaultRoleId })));
  }, [defaultRoleId, people, setOpenDialog]);
  const handleNext = React.useCallback((): void => setActiveStep(prevActiveStep => prevActiveStep + 1), []);
  const handleBack = React.useCallback((): void => setActiveStep(prevActiveStep => prevActiveStep - 1), []);
  const handleTitleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => setTitle(event.target.value),
    [],
  );
  const handleDescriptionChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => setDescription(event.target.value),
    [],
  );
  const disabledStepDetail = React.useMemo(() => title.length === 0, [title]);
  const disabledStepRoles = React.useMemo(
    () => people.filter(person => person.roleId !== defaultRoleId).length === 0 || disabledFinish,
    [defaultRoleId, people, disabledFinish],
  );

  const saveConversation = React.useCallback((): void => {
    setDisabledFinish(true);
    const personData = people.find(person => person.email === user.email);
    const newConversation: Conversation = {
      id: '',
      title,
      description,
      beginDate: '',
      endDate: '',
      files: [],
      creator: personData !== undefined ? personData.id : '',
      stageId: initStagesId,
      participants: people.filter(person => person.roleId !== defaultRoleId),
      projectId: '',
    };
    setConversation(newConversation)
      .then((response: Conversation) => {
        const stageSelect: Stage | undefined = stages.find(stage => stage.id === response.stageId);
        if (stageSelect !== undefined) {
          createDocument('', personData !== undefined ? personData.id : '', response.id, initStagesId);
          createDocument('', personData !== undefined ? personData.id : '', response.id, stages[1].id);
          createDocument('', personData !== undefined ? personData.id : '', response.id, stages[2].id);
          createDocument('', personData !== undefined ? personData.id : '', response.id, stages[3].id);
          const newConversationNegotiation: Conversation = {
            id: response.id,
            title,
            description,
            beginDate: '',
            endDate: '',
            files: [],
            creator: personData !== undefined ? personData.id : '',
            stageId: stages[1].id,
            participants: people.filter(person => person.roleId !== defaultRoleId),
            projectId: '',
          };
          updateConversation(newConversationNegotiation)
            .then(() => {
              const newConversationRealization: Conversation = {
                id: response.id,
                title,
                description,
                beginDate: '',
                endDate: '',
                files: [],
                creator: personData !== undefined ? personData.id : '',
                stageId: stages[2].id,
                participants: people.filter(person => person.roleId !== defaultRoleId),
                projectId: '',
              };
              updateConversation(newConversationRealization)
                .then(() => {
                  const newConversationEvaluation: Conversation = {
                    id: response.id,
                    title,
                    description,
                    beginDate: '',
                    endDate: '',
                    files: [],
                    creator: personData !== undefined ? personData.id : '',
                    stageId: stages[3].id,
                    participants: people.filter(person => person.roleId !== defaultRoleId),
                    projectId: '',
                  };
                  updateConversation(newConversationEvaluation)
                    .then(() => {
                      navigate(routes.context.replace(/:id/i, response.id), { state: { conversation: response } });
                    })
                    .catch(() => {
                      setOpenDialogError(true);
                      setMessageError('No se pudo crear la conversación correctamente.');
                    });
                })
                .catch(() => {
                  setOpenDialogError(true);
                  setMessageError('No se pudo crear la conversación correctamente.');
                });
            })
            .catch(() => {
              setOpenDialogError(true);
              setMessageError('No se pudo crear la conversación correctamente.');
            });
        }
      })
      .catch(() => {
        setOpenDialogError(true);
        setMessageError('No se pudo crear la conversación correctamente.');
      });
  }, [defaultRoleId, description, initStagesId, navigate, people, stages, title, user.email]);

  React.useEffect(() => {
    getRoles()
      .then((response: Role[]) => {
        const updatedRoles = response.sort((firstItem: Role, secondItem: Role) => firstItem.key - secondItem.key);
        setRoles(updatedRoles);
        const defaultRole = updatedRoles[0];
        setDefaultRoleId(defaultRole.id);
        getPeople()
          .then((response: Person[]) => {
            const updatedPersons = response.map((person: Person) => {
              return {
                abbreviation: person.abbreviation,
                email: person.email,
                id: person.id,
                key: person.id,
                name: person.name,
                roleId: defaultRole.id,
              };
            });
            setPeople(updatedPersons);
            getStages()
              .then((response: Stage[]) => {
                const updatedStages = response
                  .filter(stage => stage.key !== undefined)
                  .sort((firstItem: Stage, secondItem: Stage) => firstItem.key - secondItem.key);
                setStages(updatedStages);
                setInitStagesId(updatedStages[0].id);
              })
              .catch(() => {
                setOpenDialogError(true);
                setMessageError('No se pudieron obtener las etapas desde la base de datos.');
              });
          })
          .catch(() => {
            setOpenDialogError(true);
            setMessageError('No se pudieron obtener las personas desde la base de datos.');
          });
      })
      .catch(() => {
        setOpenDialogError(true);
        setMessageError('No se pudieron obtener los roles desde la base de datos.');
      });
  }, []);

  return (
    <Styles.Root>
      <Styles.DialogConversation fullWidth maxWidth="md" open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle id="alert-dialog-title">
          <Typography>Nueva conversación</Typography>
          <Styles.DialogStepper activeStep={activeStep} orientation="horizontal">
            {steps.map(label => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Styles.DialogStepper>
        </DialogTitle>
        <DialogContent dividers>
          {activeStep === 0 && (
            <>
              <Common.GridContainer padding="10px 70px">
                <Common.GridItem md={12} xs={12}>
                  <TextField fullWidth label="Título" variant="outlined" value={title} onChange={handleTitleChange} />
                </Common.GridItem>
              </Common.GridContainer>
              <Common.GridContainer padding="10px 70px">
                <Common.GridItem md={12} xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    label="Descripción"
                    rows={4}
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                </Common.GridItem>
              </Common.GridContainer>
            </>
          )}
          {activeStep === 1 && <ParticipantsList people={people} roles={roles} setPeople={setPeople} />}
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={activeStep === 0} sx={{ mr: 1 }} onClick={handleBack}>
            Back
          </Button>
          {activeStep === 0 && (
            <Button disabled={disabledStepDetail} onClick={handleNext}>
              Next
            </Button>
          )}
          {activeStep === 1 && (
            <Button disabled={disabledStepRoles} onClick={saveConversation}>
              Finish
            </Button>
          )}
        </DialogActions>
      </Styles.DialogConversation>
      <DialogError openDialog={openDialogError} setOpenDialog={setOpenDialogError} message={messageError} />
    </Styles.Root>
  );
};

export default CreateConversation;
