import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { AWSAppSyncRealTimeProvider } from '@aws-amplify/pubsub';
import Observable, { ZenObservable } from 'zen-observable-ts';
import {
  ConversationEventFinished,
  queries,
  mutations,
  subscriptions,
  OnCreateConversationEventFinishedSubscription,
} from '../modules/amplify';

export type { ConversationEventFinished } from '../modules/amplify';

interface GetConversationEventFinishedData {
  conversationEventFinishedByCreatedAt: {
    items: ConversationEventFinished[];
    nextToken: null;
  };
}
type GetConversationEventFinishedService = (
  conversationId: string,
  stageId: string,
) => Promise<ConversationEventFinished[]>;

export const getConversationEventFinished: GetConversationEventFinishedService = (conversationId, stageId) => {
  const operation = {
    query: queries.conversationEventFinishedByCreatedAt,
    variables: {
      conversation: conversationId,
      stage: stageId,
      sortDirection: 'DESC',
    },
  };

  return (API.graphql(operation) as Promise<GraphQLResult<GetConversationEventFinishedData>>).then(
    response => response.data?.conversationEventFinishedByCreatedAt?.items ?? [],
  );
};

type ConversationEventFinishedSubscriptionCallback = (
  EventFinished: OnCreateConversationEventFinishedSubscription['onCreateConversationEventFinished'],
) => void;

type ConversationEventFinishedSubscription = Observable<{
  provider: AWSAppSyncRealTimeProvider;
  value: GraphQLResult<OnCreateConversationEventFinishedSubscription>;
}>;

type GetConversationEventFinishedSubscription = (
  conversationId: string,
  stageId: string,
  callback: ConversationEventFinishedSubscriptionCallback,
) => ZenObservable.Subscription;

export const getConversationEventFinishedSubscription: GetConversationEventFinishedSubscription = (
  conversationId,
  stageId,
  callback,
) => {
  const operation = graphqlOperation(subscriptions.onCreateConversationEventFinished, {
    filter: {
      conversation: {
        eq: conversationId,
      },
      stage: {
        eq: stageId,
      },
    },
  });
  const subscription = (API.graphql(operation) as unknown as ConversationEventFinishedSubscription).subscribe({
    error: (error: Error) => {
      throw error;
    },
    next: event => callback(event.value.data?.onCreateConversationEventFinished),
  });

  return subscription;
};

interface CreateConversationEventFinishedData {
  createConversationEventFinished: ConversationEventFinished;
}
type CreateConversationEventFinishedService = (
  type: string,
  authorId: string,
  conversationId: string,
  stageId: string,
) => Promise<ConversationEventFinished | null>;

export const createConversationEventFinished: CreateConversationEventFinishedService = async (
  type,
  authorId,
  conversationId,
  stageId,
) => {
  const input = {
    type,
    author: authorId,
    conversation: conversationId,
    stage: stageId,
  };
  const operation = {
    query: mutations.createConversationEventFinished,
    variables: {
      input,
    },
  };

  const response = await (API.graphql(operation) as Promise<GraphQLResult<CreateConversationEventFinishedData>>);
  return response.data?.createConversationEventFinished ?? null;
};

interface DeleteConversationEventFinishedData {
  deleteConversationEventFinished: ConversationEventFinished;
}
type DeleteConversationEventFinishedService = (id: string) => Promise<ConversationEventFinished | null>;

export const deleteConversationEventFinished: DeleteConversationEventFinishedService = id => {
  const input = {
    id,
  };
  const operation = {
    query: mutations.deleteConversationEventFinished,
    variables: {
      input,
    },
  };

  return (API.graphql(operation) as Promise<GraphQLResult<DeleteConversationEventFinishedData>>).then(
    response => response.data?.deleteConversationEventFinished ?? null,
  );
};
