import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, ToggleButton, ToggleButtonGroup, useMediaQuery, useTheme, Chip } from '@mui/material';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import routes from '../config/routes';
import { getConversations, ConversationHome } from '../services/conversations';
import { getStages, Stage } from '../services/stages';
import BrandLayout from '../components/Layouts/BrandLayout';
import DialogError from '../components/DialogError/DialogError';
import CreateConversation from '../containers/CreateConversation/CreateConversation.container';
import ProfileMenu from '../containers/ProfileMenu/ProfileMenu.container';
import * as Common from '../components/Styles/Common.styles';
import * as Styles from './Home.styles';
import moment from 'moment-timezone';

type StageRoute = Record<number, string>;

const STAGES_ROUTES: StageRoute = {
  0: routes.context,
  1: routes.negotiation,
  2: routes.realization,
  3: routes.evaluation,
};

const Home: React.FC = () => {
  const [openDialogError, setOpenDialogError] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  const [stageSelect] = React.useState('');
  const [conversationsTable, setConversationsTable] = React.useState<string[][][][]>([]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const optionsData: MUIDataTableOptions = {
    filterType: 'multiselect',
    download: 'false',
    print: 'false',
  };
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpenDialog = (): void => setOpenDialog(true);
  const [stages, setStages] = React.useState<Stage[]>([]);

  const CONVERSATIONS_COLUMNS = [
    { name: 'Id', options: { filter: false, display: false } },
    { name: 'Titulo', options: { filter: false, display: true } },
    { name: 'Descripción', options: { filter: false, display: true } },
    {
      name: 'Fecha inicial',
      options: {
        filter: false,
      },
    },
    {
      name: 'Creador',
      options: {
        filter: true,
        display: true,
        filterOptions: {
          logic: (value: string, filter: any[]) => {
            if (filter.length === 0) {
              return true;
            }
            return !filter.some(val => {
              return val.includes(value);
            });
          },
          renderValue: (v: string) => {
            return v.slice(0, -4);
          },
          fullWidth: true,
        },
        customFilterListOptions: {
          render: (v: string) => {
            return v.slice(0, -4);
          },
        },
        customBodyRender: (value: string[]) => {
          return value.map(val => <Chip key={val[0]} title={val[0]} label={val[1]} variant="outlined" />);
        },
      },
    },
    {
      name: 'Participantes',
      options: {
        filter: true,
        display: true,
        customFilterListOptions: {
          render: (v: string) => {
            return v.slice(0, -4);
          },
        },
        filterOptions: {
          logic: (value: string, filter: any[]) => {
            if (filter.length === 0) {
              return true;
            }
            for (let i = 0; i < value.length; i++) {
              if (
                filter.some(val => {
                  return val.includes(value[i]);
                })
              ) {
                return false;
              }
            }
            return true;
          },
          renderValue: (v: string) => {
            return v.slice(0, -4);
          },
          fullWidth: true,
        },
        customBodyRender: (value: string[]) => {
          return value.map(val => <Chip key={val[0]} title={val[0]} label={val[1]} variant="outlined" />);
        },
      },
    },
    {
      name: 'Etapas',
      options: {
        filter: false,
        customBodyRender: (value: string) => {
          return (
            <ToggleButtonGroup
              exclusive
              aria-label="Platform"
              color="primary"
              value={stageSelect}
              onChange={handleStageSelect(value)}
            >
              {stages.map(stage => (
                <ToggleButton key={stage.id} value={stage.id} sx={{ fontSize: '10px' }}>
                  {stage.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          );
        },
      },
    },
  ];

  React.useEffect(() => {
    getStages()
      .then((response: Stage[]) => {
        const updatedStages = response
          .filter(stage => stage.key !== undefined)
          .sort((firstItem: Stage, secondItem: Stage) => firstItem.key - secondItem.key);
        setStages(updatedStages);
        getConversations()
          .then((response: ConversationHome[]) => {
            const collection = response.map(conversation => [
              [[conversation.id]],
              [[conversation.title]],
              [[conversation.description]],
              [[moment(conversation.beginDate).tz('America/Santiago').format('YYYY-MM-DD HH:mm:ss')]],
              [[conversation.creator?.name ?? '', conversation.creator?.abbreviation ?? '']],
              conversation.participants.map(participant => [participant.name, participant.abbreviation]),
              [[conversation.id]],
            ]);
            setConversationsTable(collection);
          })
          .catch(() => {
            setOpenDialogError(true);
            setMessageError('No se pudieron obtener las conversaciones desde la base de datos.');
          });
      })
      .catch(() => {
        setOpenDialogError(true);
        setMessageError('No se pudieron obtener las etapas desde la base de datos.');
      });
  }, []);

  const handleStageSelect = (conversationId: string) => (_event: React.MouseEvent<HTMLElement>, value: string) => {
    const stageSelect: Stage | undefined = stages.find(stage => stage.id === value);
    if (stageSelect !== undefined) {
      navigate(STAGES_ROUTES[stageSelect.key].replace(/:id/i, conversationId[0][0]), {
        state: { conversation: { id: conversationId[0][0], stageId: value } },
      });
    }
  };

  return (
    <BrandLayout profileMenu={<ProfileMenu />}>
      <Styles.Divider />
      <Common.GridContainer padding="20px 0">
        <Common.GridItem md={4} textAlign={matches ? 'center' : 'left'} xs={12}>
          <Typography variant="h5">CONVERSACIONES</Typography>
        </Common.GridItem>
        <Common.GridItem md={8} textAlign={matches ? 'center' : 'right'} xs={12}>
          <Button
            disableElevation
            disableRipple
            color="gi-secondary"
            variant="outlined"
            onClick={handleClickOpenDialog}
          >
            Nueva conversación
          </Button>
          <CreateConversation openDialog={openDialog} setOpenDialog={setOpenDialog} />
        </Common.GridItem>
      </Common.GridContainer>
      <Common.GridContainer>
        <Common.GridItem xs={12}>
          <MUIDataTable columns={CONVERSATIONS_COLUMNS} data={conversationsTable} options={optionsData} title="" />
        </Common.GridItem>
      </Common.GridContainer>
      <DialogError openDialog={openDialogError} message={messageError} setOpenDialog={setOpenDialogError} />
    </BrandLayout>
  );
};

export default Home;
