// Palettes generated from https://m2.material.io/inline-tools/color/

export const primary = {
  50: '#fce7d5',
  100: '#e2c6b3',
  200: '#c4a28d',
  300: '#a48066',
  400: '#8d6549', // main
  500: '#764c2d',
  600: '#6b4327',
  700: '#5c371f',
  800: '#4e2b17',
  900: '#3f1d0d',
};

export const secondary = {
  50: '#edeeef',
  100: '#d1d5da',
  200: '#b4b9c3',
  300: '#969dab',
  400: '#7f8899',
  500: '#697488',
  600: '#5c6677',
  700: '#4b5261',
  800: '#3b404b',
  900: '#282c34', // main
};
