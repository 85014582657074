import React from 'react';
import {
  Dialog as DialogBase,
  Stepper as StepperBase,
  styled,
  TableCell as TableCellBase,
  TableCellProps,
} from '@mui/material';
import * as Common from '../../components/Styles/Common.styles';

export const Root = styled(Common.Root)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

interface ITableCellProps extends TableCellProps {
  width?: React.CSSProperties['width'];
}

export const TableCell = styled(({ width, ...props }: ITableCellProps) => <TableCellBase {...props} />)`
  &.MuiTableCell-root {
    width: ${props => props.width};
  }
`;

export const DialogConversation = styled(DialogBase)`
  & .MuiDialogContent-root {
    padding: ${props => props.theme.spacing(2)};
    max-height: 400px;
  }
  & .MuiDialogActions-root {
    padding: ${props => props.theme.spacing(2)};
  }
`;

export const DialogStepper = styled(StepperBase)`
  &.MuiStepper-root {
    padding: 20px;
  }
`;
