import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import routes from '../../config/routes';
import * as Common from '../Styles/Common.styles';
import * as Styles from './BrandLayout.styles';

interface BrandLayoutProps {
  children: React.ReactNode;
  profileMenu: React.ReactNode;
}

const BrandLayout: React.FC<BrandLayoutProps> = props => {
  const { children, profileMenu } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const handleGoHome = React.useCallback(() => navigate(routes.home), [navigate]);

  return (
    <Styles.Root>
      <Common.GridContainer alignItems="center" padding="0 50px">
        <Common.GridItem md={4} xs={12}>
          <Styles.Image alt="Geoinnova" height="100px" src="/assets/images/logo.png" onClick={handleGoHome} />
        </Common.GridItem>
        <Common.GridItem md={8} textAlign={matches ? 'center' : 'right'} xs={12}>
          {profileMenu}
        </Common.GridItem>
      </Common.GridContainer>
      <Common.GridContainer alignItems="center" padding="0 50px">
        <Common.GridItem xs={12}>{children}</Common.GridItem>
      </Common.GridContainer>
      <Styles.Footer margin="auto 0 0" padding="5px 50px">
        <Common.GridItem xs={12}>
          <Typography color="white">&copy; Geoinnova</Typography>
        </Common.GridItem>
      </Styles.Footer>
    </Styles.Root>
  );
};

export default BrandLayout;
