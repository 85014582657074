import React from 'react';
import { useUser } from '../../modules/amplify';
import { getDocument, getDocumentSubscription, updateDocument } from '../../services/documents';
import Editor, { EditorRef, useEditor } from '../../components/Editor/Editor';
import * as Common from '../../components/Styles/Common.styles';

interface DocumentSectionProps {
  documentId: string;
  desactivateEditor: boolean;
  onClick: () => void;
  onBlur: () => void;
}

const DocumentSection: React.FC<DocumentSectionProps> = props => {
  const { documentId, desactivateEditor, onClick, onBlur } = props;
  const { ref: editorRef, updateContent } = useEditor();
  const user = useUser();
  const handleChange = React.useCallback(
    (_editor: EditorRef, content = '') => {
      updateDocument(documentId, user.id, content);
    },
    [documentId, user.id],
  );
  const handleLoad = React.useCallback(() => {
    getDocument(documentId).then(doc => {
      if (doc?.content === null) {
        return;
      }

      updateContent(doc?.content ?? '');
    });
  }, [documentId, updateContent]);

  const handleClick = React.useCallback(() => {
    onClick();
  }, []);

  const handleBlur = React.useCallback(() => {
    onBlur();
  }, []);

  React.useEffect(() => {
    const subscription = getDocumentSubscription(documentId, event => {
      if (event?.author === user.id) {
        return;
      }

      // FIXME: Need to figure out how to keep the cursor position (caret) after
      // updating the document from the subscription, since right now it is pushed
      // to the end on each update.
      editorRef.current.instance?.selection.select(editorRef.current.instance.getBody());

      const bookmark = editorRef.current.instance?.selection.getBookmark();

      updateContent(event?.content ?? '');
      editorRef.current.instance?.selection.moveToBookmark(bookmark as typeof editorRef.current.instance.bookmark);
      editorRef.current.instance?.selection.collapse(false);
    });

    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, updateContent, user.id]);

  return (
    <Common.Root>
      <Common.GridContainer>
        <Common.GridItem xs={12}>
          <Editor
            ref={editorRef}
            onChange={handleChange}
            onLoad={handleLoad}
            onClick={handleClick}
            onBlur={handleBlur}
            deactivateEditor={desactivateEditor}
          />
        </Common.GridItem>
      </Common.GridContainer>
    </Common.Root>
  );
};

export default DocumentSection;
