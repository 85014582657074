import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  Chip,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
} from '@mui/material';
import { getPeople, Person } from '../services/people';
import { getRoles, Role as RoleBase } from '../services/roles';
import { Stages } from '../services/stages';
import BrandLayout from '../components/Layouts/BrandLayout';
import CreateEntry from '../containers/CreateEntry/CreateEntry.container';
import CycleBar from '../containers/CycleBar/CycleBar.container';
import DocumentSection from '../containers/DocumentSection/DocumentSection.container';
import EntriesList from '../containers/EntriesList/EntriesList.container';
import ParticipantsList from '../containers/ParticipantsList/ParticipantsList.container';
import ProfileMenu from '../containers/ProfileMenu/ProfileMenu.container';
import * as Common from '../components/Styles/Common.styles';
import * as Styles from './Context.styles';
import { getProjects, Project as ProjectBase } from '../services/projects';
import { getDocumentId } from '../services/documents';
import {
  getConversationByStage,
  Conversation,
  updateProjectConversation,
  ConversationProject,
  updateTitleConversation,
  ConversationTitle,
  updateDescriptionConversation,
  ConversationDescription,
  updateParticipantsConversation,
  ConversationParticipants,
} from '../services/conversations';
import { useUser } from '../modules/amplify';
import { getConversationEventSubscription, createConversationEvent, Event } from '../services/conversationEvent';
import {
  getConversationEventFinishedSubscription,
  createConversationEventFinished,
} from '../services/conversationEventFinished';
import DialogError from '../components/DialogError/DialogError';
import Upload from '../containers/FilesManager/FileUpload';
import FileList from '../containers/FilesManager/FileList';
import { BucketFile, getFiles } from '../services/files';

const DEFAULT_CHIP_COLOR = '#ababab';

interface Project extends ProjectBase {
  label: string;
}

interface Role extends RoleBase {
  color: string;
}

type ChipColor = Record<number, string>;

const ChipColors: ChipColor = {
  0: '#f3e5f5',
  1: '#90caf9',
  2: '#66bb6a',
  3: '#ffa726',
  4: '#f07167',
};

const tags = [
  { id: 1, tag: 'Machine learning' },
  { id: 2, tag: 'Estimación' },
  { id: 3, tag: 'Simulación' },
  { id: 4, tag: 'Organización' },
  { id: 5, tag: 'Conciliación' },
  { id: 6, tag: 'Administración' },
];

const Context: React.FC = () => {
  const location = useLocation();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [disabledTitle, setDisabledTitle] = React.useState(false);
  const [disableSave, setdisableSave] = React.useState(false);
  const { id = '' } = useParams();
  const user = useUser();
  const { id: conversationId = '' } = useParams();
  const [projects, setProjects] = React.useState<Project[]>([]);
  const [disabledProject, setDisabledProject] = React.useState(false);
  const [disabledDescription, setDisabledDescription] = React.useState(false);
  const [disabledTags, setDisabledTags] = React.useState(false);
  const [disabledParticipants, setDisabledParticipants] = React.useState(false);
  const [desactivateEditor, setDesactivateEditor] = React.useState(false);
  const [people, setPeople] = React.useState<Person[]>([]);
  const [files, setFiles] = React.useState<BucketFile[]>([]);
  const [title, setTitle] = React.useState('');
  const [roles, setRoles] = React.useState<Role[]>([]);
  const [participants, setParticipants] = React.useState<Person[]>([]);
  const [tagsSelect, setTagsSelect] = React.useState<string[]>([]);
  const [projectSelect, setProjectSelect] = React.useState<Project | null>(null);
  const [description, setDescription] = React.useState('');
  const [openDialogError, setOpenDialogError] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  const [defaultRoleId, setDefaultRoleId] = React.useState('');
  const [documentId, setDocumentId] = React.useState('');
  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDescription(event.target.value);
  };
  const focusTitle = (): void => {
    createConversationEvent(Event.title, user.id, conversationId, location.state.conversation.stageId);
  };
  const focusProject = (): void => {
    createConversationEvent(Event.project, user.id, conversationId, location.state.conversation.stageId);
  };
  const focusDescription = (): void => {
    createConversationEvent(Event.description, user.id, conversationId, location.state.conversation.stageId);
  };
  const focusTags = (): void => {
    createConversationEvent(Event.tags, user.id, conversationId, location.state.conversation.stageId);
  };
  const focusDocument = (): void => {
    createConversationEvent(Event.document, user.id, conversationId, location.state.conversation.stageId);
  };
  const handleTitle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(event.target.value);
  };
  const blurProject = (): void => {
    const projectConversation: ConversationProject = {
      id: conversationId,
      projectId: projectSelect?.id ?? '',
    };
    updateProjectConversation(projectConversation)
      .then(() => {
        createConversationEventFinished(Event.project, user.id, conversationId, location.state.conversation.stageId);
      })
      .catch(() => {
        setOpenDialogError(true);
        setMessageError('No se pudo actualizar el proyecto de la conversacion.');
      });
  };
  const blurTitle = (): void => {
    const titleConversation: ConversationTitle = {
      id: conversationId,
      title,
    };
    updateTitleConversation(titleConversation)
      .then(() => {
        createConversationEventFinished(Event.title, user.id, conversationId, location.state.conversation.stageId);
      })
      .catch(() => {
        setOpenDialogError(true);
        setMessageError('No se pudo actualizar el titulo de la conversacion.');
      });
  };
  const blurDescription = (): void => {
    const descriptionConversation: ConversationDescription = {
      id: conversationId,
      description,
    };
    updateDescriptionConversation(descriptionConversation)
      .then(() => {
        createConversationEventFinished(
          Event.description,
          user.id,
          conversationId,
          location.state.conversation.stageId,
        );
      })
      .catch(() => {
        setOpenDialogError(true);
        setMessageError('No se pudo actualizar el titulo de la conversacion.');
      });
  };
  const blurTags = (): void => {
    const projectConversation: ConversationProject = {
      id: conversationId,
      projectId: projectSelect?.id ?? '',
    };
    updateProjectConversation(projectConversation)
      .then(() => {
        createConversationEventFinished(Event.tags, user.id, conversationId, location.state.conversation.stageId);
      })
      .catch(() => {
        setOpenDialogError(true);
        setMessageError('No se pudo actualizar el proyecto de la conversacion.');
      });
  };
  const blurDocument = (): void => {
    createConversationEventFinished(Event.document, user.id, conversationId, location.state.conversation.stageId);
  };

  React.useEffect(() => {
    if (location.state.conversation !== undefined) {
      setTitle(location.state.conversation.title !== undefined ? location.state.conversation.title : '');
      setDescription(
        location.state.conversation.description !== undefined ? location.state.conversation.description : '',
      );
      setParticipants(
        location.state.conversation.participants !== undefined
          ? location.state.conversation.participants.sort(
              (firstItem: Person, secondItem: Person) =>
                (roles.find(role => role.id === firstItem.roleId)?.key ?? -1) -
                (roles.find(role => role.id === secondItem.roleId)?.key ?? -1),
            )
          : [],
      );
    }
    getFiles(conversationId)
      .then((response: BucketFile[]) => setFiles(response))
      .catch(() => {
        setOpenDialogError(true);
        setMessageError('No se pudieron obtener los archivos asociados a la conversación');
      });
    getProjects()
      .then((response: ProjectBase[]) => {
        const updatedProjects = response.map((project: ProjectBase) => {
          return { ...project, label: project.name };
        });
        setProjects(updatedProjects);
        getRoles()
          .then((response: Role[]) => {
            const updatedRoles = response.sort((firstItem: Role, secondItem: Role) => firstItem.key - secondItem.key);
            setRoles(
              updatedRoles.map((role: Role) => {
                return {
                  id: role.id,
                  key: role.key,
                  name: role.name,
                  color: role.key in ChipColors ? ChipColors[role.key] : DEFAULT_CHIP_COLOR,
                };
              }),
            );
            const defaultRole = updatedRoles[0];
            setDefaultRoleId(defaultRole.id);
            getPeople()
              .then((response: Person[]) => {
                const updatedPersons = response.map((person: Person) => {
                  return {
                    abbreviation: person.abbreviation,
                    email: person.email,
                    id: person.id,
                    key: person.id,
                    name: person.name,
                    roleId: defaultRole.id,
                  };
                });
                setPeople(updatedPersons);

                if (location.state.conversation !== undefined) {
                  getConversationByStage(location.state.conversation.id, location.state.conversation.stageId)
                    .then((response: Conversation) => {
                      setTitle(response.title !== undefined ? response.title : '');
                      setDescription(response.description !== undefined ? response.description : '');
                      response.participants = response.participants.map(participant => {
                        return {
                          abbreviation: updatedPersons.find(person => person.id === participant.id)?.abbreviation ?? '',
                          email: updatedPersons.find(person => person.id === participant.id)?.email ?? '',
                          id: participant.id,
                          key: updatedPersons.find(person => person.id === participant.id)?.key ?? '',
                          name: updatedPersons.find(person => person.id === participant.id)?.name ?? '',
                          roleId: participant.roleId,
                        };
                      });
                      const project = updatedProjects.find(obj => obj.id === response.projectId);
                      setProjectSelect(project ?? null);
                      setParticipants(
                        response.participants !== undefined
                          ? response.participants.sort(
                              (firstItem: Person, secondItem: Person) =>
                                (roles.find(role => role.id === firstItem.roleId)?.key ?? -1) -
                                (roles.find(role => role.id === secondItem.roleId)?.key ?? -1),
                            )
                          : [],
                      );
                    })
                    .catch(() => {
                      setOpenDialogError(true);
                      setMessageError('No se pudieron obtener las conversaciones desde la base de datos.');
                    });
                }
                getDocumentId(location.state.conversation.id, location.state.conversation.stageId)
                  .then(response => {
                    const idDocument =
                      response.find(document => document.stage === location.state.conversation.stageId)?.id ?? '';
                    setDocumentId(idDocument);
                  })
                  .catch(() => {
                    setOpenDialogError(true);
                    setMessageError('No se pudo obtener el documento desde la base de datos.');
                  });
              })
              .catch(() => {
                setOpenDialogError(true);
                setMessageError('No se pudieron obtener las personas desde la base de datos.');
              });
          })
          .catch(() => {
            setOpenDialogError(true);
            setMessageError('No se pudieron obtener los roles desde la base de datos.');
          });
      })
      .catch(() => {
        setOpenDialogError(true);
        setMessageError('No se pudieron obtener los proyectos desde la base de datos.');
      });
  }, [location.state.conversation]);

  React.useEffect(() => {
    const subscription = getConversationEventSubscription(
      conversationId,
      location.state.conversation.stageId,
      response => {
        if (user.id !== response?.author) {
          if (response?.type === Event.title) {
            setDisabledTitle(true);
          }
          if (response?.type === Event.project) {
            setDisabledProject(true);
          }
          if (response?.type === Event.description) {
            setDisabledDescription(true);
          }
          if (response?.type === Event.tags) {
            setDisabledTags(true);
          }
          if (response?.type === Event.participants) {
            setDisabledParticipants(true);
          }
          if (response?.type === Event.document) {
            setDesactivateEditor(true);
          }
        }
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [conversationId, user.id]);

  React.useEffect(() => {
    const subscription = getConversationEventFinishedSubscription(
      conversationId,
      location.state.conversation.stageId,
      response => {
        if (user.id !== response?.author) {
          if (response?.type === Event.title) {
            setDisabledTitle(false);
            getConversationByStage(conversationId, location.state.conversation.stageId)
              .then((responseTitle: Conversation) => {
                setTitle(responseTitle.title);
              })
              .catch(() => {
                setOpenDialogError(true);
                setMessageError('No se pudo actualizar el titulo de la conversacion.');
              });
          }
          if (response?.type === Event.project) {
            setDisabledProject(false);
            getConversationByStage(conversationId, location.state.conversation.stageId)
              .then((responseProject: Conversation) => {
                const project = projects.find(obj => obj.id === responseProject.projectId);
                setProjectSelect(project ?? null);
              })
              .catch(() => {
                setOpenDialogError(true);
                setMessageError('No se pudo actualizar el projecto de la conversacion.');
              });
          }
          if (response?.type === Event.description) {
            setDisabledDescription(false);
            getConversationByStage(conversationId, location.state.conversation.stageId)
              .then((responseDescription: Conversation) => {
                setDescription(responseDescription.description);
              })
              .catch(() => {
                setOpenDialogError(true);
                setMessageError('No se pudo actualizar la descripcion de la conversacion.');
              });
          }
          if (response?.type === Event.tags) {
            setDisabledTags(false);
          }
          if (response?.type === 'UpdateParticipantsFree') {
            setDisabledParticipants(false);
          }
          if (response?.type === Event.participants) {
            setDisabledParticipants(false);
            getConversationByStage(conversationId, location.state.conversation.stageId)
              .then((responseParticipants: Conversation) => {
                responseParticipants.participants = responseParticipants.participants.map(participant => {
                  return {
                    abbreviation: people.find(person => person.id === participant.id)?.abbreviation ?? '',
                    email: people.find(person => person.id === participant.id)?.email ?? '',
                    id: participant.id,
                    key: people.find(person => person.id === participant.id)?.key ?? '',
                    name: people.find(person => person.id === participant.id)?.name ?? '',
                    roleId: participant.roleId,
                  };
                });
                setParticipants(
                  responseParticipants.participants !== undefined
                    ? responseParticipants.participants.sort(
                        (firstItem: Person, secondItem: Person) =>
                          (roles.find(role => role.id === firstItem.roleId)?.key ?? -1) -
                          (roles.find(role => role.id === secondItem.roleId)?.key ?? -1),
                      )
                    : [],
                );
              })
              .catch(() => {
                setOpenDialogError(true);
                setMessageError('No se pudo actualizar los Participantes de la conversacion.');
              });
          }
          if (response?.type === Event.document) {
            setDesactivateEditor(false);
          }
        }
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [conversationId, user.id, projects, location.state.conversation.stageId, people]);

  const handleOpenDialog = React.useCallback((): void => {
    setdisableSave(false);
    createConversationEvent(Event.participants, user.id, conversationId, location.state.conversation.stageId);
    setOpenDialog(true);
    const updatedPersons = people.map((person: Person) => {
      return {
        abbreviation: person.abbreviation,
        email: person.email,
        id: person.id,
        key: person.id,
        name: person.name,
        roleId: participants.find(participant => participant.id === person.id)?.roleId ?? defaultRoleId,
      };
    });
    setPeople(updatedPersons);
  }, [defaultRoleId, participants, people]);

  const handleCloseDialog = React.useCallback((): void => {
    setOpenDialog(false);
    const updatedPersons = people.map((person: Person) => {
      return {
        abbreviation: person.abbreviation,
        email: person.email,
        id: person.id,
        key: person.id,
        name: person.name,
        roleId: participants.find(participant => participant.id === person.id)?.roleId ?? defaultRoleId,
      };
    });
    setPeople(updatedPersons);
    createConversationEventFinished(
      'UpdateParticipantsFree',
      user.id,
      conversationId,
      location.state.conversation.stageId,
    );
  }, [defaultRoleId, location.state.conversation.stageId, participants, people]);

  const handleSaveDialog = React.useCallback((): void => {
    setOpenDialog(false);
    setdisableSave(true);
    const updatedParticipants = people.filter(people => people.roleId !== defaultRoleId);
    setParticipants(
      updatedParticipants.sort(
        (firstItem: Person, secondItem: Person) =>
          (roles.find(role => role.id === firstItem.roleId)?.key ?? -1) -
          (roles.find(role => role.id === secondItem.roleId)?.key ?? -1),
      ),
    );
    const projectParticipants: ConversationParticipants = {
      id: conversationId,
      participants: updatedParticipants ?? [],
      stageId: location.state.conversation.stageId,
    };
    updateParticipantsConversation(projectParticipants)
      .then(() => {
        createConversationEventFinished(
          Event.participants,
          user.id,
          conversationId,
          location.state.conversation.stageId,
        );
      })
      .catch(() => {
        setOpenDialogError(true);
        setMessageError('No se pudo actualizar los participantes de la conversacion.');
      });
  }, [defaultRoleId, location.state.conversation.stageId, people, roles]);

  const handleProject = React.useCallback(
    (params: AutocompleteRenderInputParams) => <TextField {...params} label="Proyectos" />,
    [],
  );

  const handleTag = React.useCallback(
    (params: AutocompleteRenderInputParams) => <TextField {...params} label="Tags" />,
    [],
  );

  const handleTagSelected = (_event: React.SyntheticEvent, newValue: string[]): void => {
    setTagsSelect(newValue);
  };

  const handleProjectSelected = (_event: React.SyntheticEvent, newValue: Project | null): void => {
    setProjectSelect(newValue);
  };

  return (
    <BrandLayout profileMenu={<ProfileMenu />}>
      <Common.GridContainer padding="50px 0">
        <CycleBar id={id} stage={Stages.context} />
      </Common.GridContainer>
      <Common.GridContainer padding="5px 20px">
        <Common.GridItem xs={12} md={6} padding="0 5px">
          <TextField
            label="Titulo"
            variant="outlined"
            fullWidth
            value={title}
            onChange={handleTitle}
            disabled={disabledTitle}
            onClick={focusTitle}
            onBlur={blurTitle}
          />
        </Common.GridItem>
        <Common.GridItem xs={12} md={6} padding="0 5px">
          <Autocomplete
            disablePortal
            options={projects}
            value={projectSelect}
            onChange={handleProjectSelected}
            renderInput={handleProject}
            disabled={disabledProject}
            onFocus={focusProject}
            onBlur={blurProject}
          />
        </Common.GridItem>
      </Common.GridContainer>
      <Common.GridContainer padding="5px 20px">
        <Common.GridItem xs={12} md={6} padding="0 5px">
          <TextField
            fullWidth
            multiline
            label="Descripción"
            rows={3}
            value={description}
            disabled={disabledDescription}
            onChange={handleDescriptionChange}
            onClick={focusDescription}
            onBlur={blurDescription}
          />
        </Common.GridItem>
        <Common.GridItem xs={12} md={6} padding="0 5px">
          <Autocomplete
            freeSolo
            multiple
            value={tagsSelect}
            onChange={handleTagSelected}
            options={tags.map(option => option.tag)}
            renderInput={handleTag}
            disabled={disabledTags}
            onFocus={focusTags}
            onBlur={blurTags}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} key={index} />
              ))
            }
          />
        </Common.GridItem>
      </Common.GridContainer>
      <Common.GridContainer padding="20px 20px">
        <Common.GridItem xs={12} md={8}>
          <Common.GridContainer>
            {participants.map(data => (
              <Common.GridItem key={data.key} padding="5px 10px">
                <Styles.Chip
                  icon={
                    <Styles.Avatar bgColor={roles.find(role => role.id === data.roleId)?.color ?? ''}>
                      {data.abbreviation}
                    </Styles.Avatar>
                  }
                  label={`${data.name} - ${roles.find(role => role.id === data.roleId)?.name ?? ''}`}
                  bgColor={roles.find(role => role.id === data.roleId)?.color ?? ''}
                />
              </Common.GridItem>
            ))}
          </Common.GridContainer>
        </Common.GridItem>
        <Common.GridItem md={4} textAlign="right" xs={12}>
          <Button
            disableElevation
            disableRipple
            color="gi-secondary"
            variant="outlined"
            onClick={handleOpenDialog}
            disabled={disabledParticipants}
          >
            Cambiar Participantes / Roles
          </Button>
          <Styles.DialogConversation fullWidth maxWidth="md" open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>
              <Typography>Cambiar Participantes / Roles</Typography>
            </DialogTitle>
            <DialogContent dividers>
              <ParticipantsList people={people} roles={roles} setPeople={setPeople} />
            </DialogContent>
            <DialogActions>
              <Common.GridContainer padding="15px 0">
                <Common.GridItem md={12} textAlign="right" xs={12}>
                  <Button
                    disableElevation
                    disableRipple
                    color="gi-secondary"
                    variant="outlined"
                    onClick={handleSaveDialog}
                    disabled={disableSave}
                  >
                    Guardar
                  </Button>
                </Common.GridItem>
              </Common.GridContainer>
            </DialogActions>
          </Styles.DialogConversation>
        </Common.GridItem>
      </Common.GridContainer>
      <Common.GridContainer padding="50px 0">
        <Common.GridItem xs={6}>
          <FileList s3KeyPrefix={conversationId} files={files} />
        </Common.GridItem>
        <Common.GridItem xs={6}>
          <Upload
            s3KeyPrefix={conversationId}
            setFiles={setFiles}
            files={files}
            userId={user.id}
            conversationId={conversationId}
            stageId={location.state.conversation.stageId}
          />
        </Common.GridItem>
      </Common.GridContainer>
      <Common.GridContainer padding="50px 0 0">
        <Common.GridItem xs={12}>
          <EntriesList stage={Stages.context} people={people} />
        </Common.GridItem>
      </Common.GridContainer>
      <Common.GridContainer padding="10px 0">
        <Common.GridItem xs={12}>
          <CreateEntry stage={Stages.context} />
        </Common.GridItem>
      </Common.GridContainer>
      <Common.GridContainer padding="50px 0">
        <Common.GridItem xs={12}>
          {documentId.length > 0 && (
            <DocumentSection
              documentId={documentId}
              desactivateEditor={desactivateEditor}
              onClick={focusDocument}
              onBlur={blurDocument}
            />
          )}
        </Common.GridItem>
      </Common.GridContainer>
      <DialogError openDialog={openDialogError} setOpenDialog={setOpenDialogError} message={messageError} />
    </BrandLayout>
  );
};

export default Context;
