import React from 'react';
import { Navigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import routes from '../../config/routes';
import { useSession } from '../../modules/amplify';
import * as Common from '../Styles/Common.styles';

interface IProtectedProps {
  children: React.ReactNode;
}

const Protected: React.FC<IProtectedProps> = props => {
  const { children } = props;
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  useSession(
    () => setLoading(false),
    () => setError(true),
  );

  if (error) {
    return <Navigate replace to={routes.login} />;
  }

  if (!loading) {
    return <>{children}</>;
  }

  return (
    <Common.GridContainer alignItems="center" height="100%">
      <Common.GridItem textAlign="center" xs={12}>
        <CircularProgress color="gi-secondary" />
      </Common.GridItem>
    </Common.GridContainer>
  );
};

export default Protected;
