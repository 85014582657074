import { Amplify } from 'aws-amplify';
import {
  REACT_APP_AWS_APP_SYNC_ENDPOINT,
  REACT_APP_AWS_COGNITO_DOMAIN,
  REACT_APP_AWS_COGNITO_REDIRECT_URI,
  REACT_APP_AWS_COGNITO_USER_POOL_ID,
  REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  REACT_AWS_COGNITO_IDENTITY_POOL_ID,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_S3_BUCKET,
} from '../../config/environment';

const initialize = (): void => {
  Amplify.configure({
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_graphqlEndpoint: REACT_APP_AWS_APP_SYNC_ENDPOINT,
    aws_appsync_region: REACT_APP_AWS_REGION,
    aws_cognito_region: REACT_APP_AWS_REGION,
    aws_project_region: REACT_APP_AWS_REGION,
    aws_user_pools_id: REACT_APP_AWS_COGNITO_USER_POOL_ID,
    aws_user_pools_web_client_id: REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    aws_cognito_identity_pool_id: REACT_AWS_COGNITO_IDENTITY_POOL_ID,
    Auth: {
      identityPoolId: REACT_AWS_COGNITO_IDENTITY_POOL_ID,
      region: REACT_APP_AWS_REGION,
      userPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,
      userPoolWebClientId: REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    },
    oauth: {
      domain: REACT_APP_AWS_COGNITO_DOMAIN,
      redirectSignIn: REACT_APP_AWS_COGNITO_REDIRECT_URI,
      redirectSignOut: REACT_APP_AWS_COGNITO_REDIRECT_URI,
      responseType: 'code',
      scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
    },
    Storage: {
      AWSS3: {
        bucket: REACT_APP_AWS_S3_BUCKET,
        region: REACT_APP_AWS_REGION,
      },
    },
  });
};

export { INITIAL_UNAUTHENTICATED_ERROR, useLogin, useLogout, useSession, useUser } from './auth';

export * from './g/api';
export * as mutations from './g/mutations';
export * as queries from './g/queries';
export * as subscriptions from './g/subscriptions';

export default initialize;
