import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { AWSAppSyncRealTimeProvider } from '@aws-amplify/pubsub';
import Observable, { ZenObservable } from 'zen-observable-ts';
import { Document, queries, mutations, subscriptions, OnUpdateDocumentSubscription } from '../modules/amplify';
import { Stages } from './stages';

export type { Document } from '../modules/amplify';

export const DOCUMENT_STAGES = {
  [Stages.context]: '1',
  [Stages.negotiation]: '2',
  [Stages.realization]: '3',
  [Stages.evaluation]: '4',
  [Stages.declaration]: '5',
};

interface GetDocumentData {
  getDocument: Document;
}
type GetDocumentService = (id: string) => Promise<Document | null>;

export const getDocument: GetDocumentService = id => {
  const operation = {
    query: queries.getDocument,
    variables: {
      id,
    },
  };

  return (API.graphql(operation) as Promise<GraphQLResult<GetDocumentData>>).then(
    response => response.data?.getDocument ?? null,
  );
};

interface GetDocumentIDData {
  documentsByCreatedAt: {
    items: Document[];
  };
}
type GetDocumentIdService = (conversationId: string, stage: Stages) => Promise<Document[] | []>;

export const getDocumentId: GetDocumentIdService = (conversationId, stage) => {
  const operation = {
    query: queries.documentsByCreatedAt,
    variables: {
      conversation: conversationId,
      stage: DOCUMENT_STAGES[stage],
    },
  };

  return (API.graphql(operation) as Promise<GraphQLResult<GetDocumentIDData>>).then(
    response => response.data?.documentsByCreatedAt?.items ?? [],
  );
};

type DocumentSubscriptionCallback = (event: OnUpdateDocumentSubscription['onUpdateDocument']) => void;
type DocumentSubscription = Observable<{
  provider: AWSAppSyncRealTimeProvider;
  value: GraphQLResult<OnUpdateDocumentSubscription>;
}>;
type GetDocumentSubscription = (id: string, callback: DocumentSubscriptionCallback) => ZenObservable.Subscription;

export const getDocumentSubscription: GetDocumentSubscription = (id, callback) => {
  const operation = graphqlOperation(subscriptions.onUpdateDocument, {
    filter: {
      id: {
        eq: id,
      },
    },
  });
  const subscription = (API.graphql(operation) as unknown as DocumentSubscription).subscribe({
    error: (error: Error) => {
      throw error;
    },
    next: event => callback(event.value.data?.onUpdateDocument),
  });

  return subscription;
};

interface CreateDocumentData {
  createDocument: Document;
}
type CreateDocumentService = (
  content: string,
  authorId: string,
  conversationId: string,
  stage: string,
) => Promise<Document | null>;

export const createDocument: CreateDocumentService = (content, authorId, conversationId, stage) => {
  const input = {
    content,
    author: authorId,
    conversation: conversationId,
    stage,
  };
  const operation = {
    query: mutations.createDocument,
    variables: {
      input,
    },
  };

  return (API.graphql(operation) as Promise<GraphQLResult<CreateDocumentData>>).then(
    response => response.data?.createDocument ?? null,
  );
};

interface UpdateDocumentData {
  updateDocument: Document;
}
type UpdateDocumentService = (id: string, authorId: string, content: string) => Promise<Document | null>;

export const updateDocument: UpdateDocumentService = (id, authorId, content) => {
  const input = {
    content,
    id,
    author: authorId,
  };
  const operation = {
    query: mutations.updateDocument,
    variables: {
      input,
    },
  };

  return (API.graphql(operation) as Promise<GraphQLResult<UpdateDocumentData>>).then(
    response => response.data?.updateDocument ?? null,
  );
};

interface DeleteDocumentData {
  deleteDocument: Document;
}
type DeleteDocumentService = (id: string) => Promise<Document | null>;

export const deleteDocument: DeleteDocumentService = id => {
  const input = {
    id,
  };
  const operation = {
    query: mutations.deleteDocument,
    variables: {
      input,
    },
  };

  return (API.graphql(operation) as Promise<GraphQLResult<DeleteDocumentData>>).then(
    response => response.data?.deleteDocument ?? null,
  );
};
