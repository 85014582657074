import cookies from 'js-cookie';
import { REACT_APP_API_BASE_URL } from '../config/environment';
import { ACCESS_TOKEN_COOKIE_KEY } from '../modules/amplify/auth';
export interface Role {
  id: string;
  key: number;
  name: string;
  color: string;
}

export const getRoles = async (): Promise<Role[]> => {
  const response = await fetch(`${REACT_APP_API_BASE_URL}/roles`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get(ACCESS_TOKEN_COOKIE_KEY) ?? ''}`,
    },
  });
  return await response.json();
};
