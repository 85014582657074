import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { AWSAppSyncRealTimeProvider } from '@aws-amplify/pubsub';
import Observable, { ZenObservable } from 'zen-observable-ts';
import {
  ConversationEvent,
  queries,
  mutations,
  subscriptions,
  OnCreateConversationEventSubscription,
} from '../modules/amplify';

export type { ConversationEvent } from '../modules/amplify';

export enum Event {
  title = 'UpdateTitle',
  project = 'UpdateProject',
  description = 'UpdateDescription',
  tags = 'UpdateTags',
  participants = 'UpdateParticipants',
  document = 'UpdateDocument',
  files = 'UpdateFiles',
}

interface GetConversationEventData {
  conversationEventByCreatedAt: {
    items: ConversationEvent[];
    nextToken: null;
  };
}

type GetConversationEventService = (conversationId: string, stageId: string) => Promise<ConversationEvent[]>;

export const getConversationEvent: GetConversationEventService = (conversationId, stageId) => {
  const operation = {
    query: queries.conversationEventByCreatedAt,
    variables: {
      conversation: conversationId,
      stage: stageId,
      sortDirection: 'DESC',
    },
  };

  return (API.graphql(operation) as Promise<GraphQLResult<GetConversationEventData>>).then(
    response => response.data?.conversationEventByCreatedAt?.items ?? [],
  );
};

type ConversationEventSubscriptionCallback = (
  event: OnCreateConversationEventSubscription['onCreateConversationEvent'],
) => void;
type ConversationEventSubscription = Observable<{
  provider: AWSAppSyncRealTimeProvider;
  value: GraphQLResult<OnCreateConversationEventSubscription>;
}>;
type GetConversationEventSubscription = (
  conversationId: string,
  stageId: string,
  callback: ConversationEventSubscriptionCallback,
) => ZenObservable.Subscription;

export const getConversationEventSubscription: GetConversationEventSubscription = (
  conversationId,
  stageId,
  callback,
) => {
  const operation = graphqlOperation(subscriptions.onCreateConversationEvent, {
    filter: {
      conversation: {
        eq: conversationId,
      },
      stage: {
        eq: stageId,
      },
    },
  });
  const subscription = (API.graphql(operation) as unknown as ConversationEventSubscription).subscribe({
    error: (error: Error) => {
      throw error;
    },
    next: event => callback(event.value.data?.onCreateConversationEvent),
  });

  return subscription;
};

interface CreateConversationEventData {
  createConversationEvent: ConversationEvent;
}
type CreateConversationEventService = (
  type: string,
  authorId: string,
  conversationId: string,
  stageId: string,
) => Promise<ConversationEvent | null>;

export const createConversationEvent: CreateConversationEventService = (type, authorId, conversationId, stageId) => {
  const input = {
    type,
    author: authorId,
    conversation: conversationId,
    stage: stageId,
  };
  const operation = {
    query: mutations.createConversationEvent,
    variables: {
      input,
    },
  };

  return (API.graphql(operation) as Promise<GraphQLResult<CreateConversationEventData>>).then(
    response => response.data?.createConversationEvent ?? null,
  );
};

interface DeleteConversationEventData {
  deleteConversationEvent: ConversationEvent;
}
type DeleteConversationEventService = (id: string) => Promise<ConversationEvent | null>;

export const deleteConversationEvent: DeleteConversationEventService = id => {
  const input = {
    id,
  };
  const operation = {
    query: mutations.deleteConversationEvent,
    variables: {
      input,
    },
  };

  return (API.graphql(operation) as Promise<GraphQLResult<DeleteConversationEventData>>).then(
    response => response.data?.deleteConversationEvent ?? null,
  );
};
