import React from 'react';
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  SelectChangeEvent,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Person } from '../../services/people';
import { Role } from '../../services/roles';
import * as Common from '../../components/Styles/Common.styles';
import * as Styles from './ParticipantsList.styles';

interface ParticipantsListProps {
  people: Person[];
  roles: Role[];
  setPeople: (value: Person[]) => void;
}

const ParticipantsList: React.FC<ParticipantsListProps> = props => {
  const { people, setPeople, roles } = props;
  const [searchPersons, setSearchPersons] = React.useState('');
  const [checkParticipantOnly, setCheckParticipantOnly] = React.useState(false);
  const [filterPersons, setFilterPersons] = React.useState<Person[]>(people);
  const [defaultRoleId] = React.useState(roles[0].id);

  const handleSelectedRole = (personSelected: Person) => (event: SelectChangeEvent<string>) => {
    const updatedPersons = people.map(person => {
      if (personSelected.id === person.id) {
        return { ...person, roleId: event.target.value };
      }
      return person;
    });

    const updatedFilterPersons: Person[] = filterPersons.map(person => {
      if (personSelected.id === person.id) {
        return { ...person, roleId: event.target.value };
      }
      return person;
    });

    setFilterPersons(updatedFilterPersons);
    setPeople(updatedPersons);
  };

  const filteredPersons = (filterValue: string, onlyParticipant: boolean): Person[] => {
    if (onlyParticipant) {
      return people.filter((person: Person) => {
        return (
          (person.name.toLowerCase().includes(filterValue.toLowerCase()) && person.roleId !== defaultRoleId) ||
          (person.abbreviation.toLowerCase().includes(filterValue.toLowerCase()) && person.roleId !== defaultRoleId)
        );
      });
    } else {
      return people.filter((person: Person) => {
        return (
          person.name.toLowerCase().includes(filterValue.toLowerCase()) ||
          person.abbreviation.toLowerCase().includes(filterValue.toLowerCase())
        );
      });
    }
    return people;
  };

  const handleSearchPersons = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterPersons(filteredPersons(event.target.value, checkParticipantOnly));
    setSearchPersons(event.target.value);
  };

  const handleChangeParticipantOnly = (_: React.SyntheticEvent, checked: boolean): void => {
    setFilterPersons(filteredPersons(searchPersons, checked));
    setCheckParticipantOnly(checked);
  };

  return (
    <Styles.Root>
      <Common.GridContainer padding="15px 20px">
        <Common.GridItem md={6} xs={12} padding="0px 15px">
          <FormControl fullWidth variant="outlined">
            <Input
              value={searchPersons}
              onChange={handleSearchPersons}
              endAdornment={
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              }
            />
          </FormControl>
        </Common.GridItem>
        <Common.GridItem md={6} xs={12}>
          <FormControl fullWidth variant="outlined">
            <FormControlLabel
              control={<Switch />}
              checked={checkParticipantOnly}
              onChange={handleChangeParticipantOnly}
              label="Solo participantes"
            />
          </FormControl>
        </Common.GridItem>
      </Common.GridContainer>
      <TableContainer component={Paper}>
        <Table aria-label="Personas">
          <TableHead>
            <TableRow>
              <Styles.TableCell>Nombre</Styles.TableCell>
              <Styles.TableCell>Abreviación</Styles.TableCell>
              <Styles.TableCell width="200px">Rol</Styles.TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterPersons.map((person: Person) => (
              <TableRow key={person.id}>
                <TableCell>{person.name}</TableCell>
                <TableCell>{person.abbreviation}</TableCell>
                <TableCell>
                  <Select value={person.roleId} onChange={handleSelectedRole(person)}>
                    {roles.map(role => (
                      <MenuItem key={role.id} value={role.id}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Styles.Root>
  );
};

export default ParticipantsList;
