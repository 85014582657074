import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import routes from './config/routes';
import Protected from './components/Auth/Protected';
import Home from './pages/Home';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Context from './pages/Context';
import Negotiation from './pages/Negotiation';
import Realization from './pages/Realization';
import Evaluation from './pages/Evaluation';

const App: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route caseSensitive element={<Navigate replace to={routes.login} />} path={routes.root} />
      <Route caseSensitive element={<Login />} path={routes.login} />
      <Route
        caseSensitive
        element={
          <Protected>
            <Home />
          </Protected>
        }
        path={routes.home}
      />
      <Route caseSensitive element={<NotFound />} path={routes.notFound} />
      <Route
        caseSensitive
        element={
          <Protected>
            <Context />
          </Protected>
        }
        path={routes.context}
      />
      <Route
        caseSensitive
        element={
          <Protected>
            <Negotiation />
          </Protected>
        }
        path={routes.negotiation}
      />
      <Route
        caseSensitive
        element={
          <Protected>
            <Realization />
          </Protected>
        }
        path={routes.realization}
      />
      <Route
        caseSensitive
        element={
          <Protected>
            <Evaluation />
          </Protected>
        }
        path={routes.evaluation}
      />
      <Route caseSensitive element={<NotFound />} path={routes.notFound} />
    </Routes>
  </BrowserRouter>
);

export default App;
