import React from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography } from '@mui/material';
import * as Styles from './DialogError.styles';

interface IDialogProps {
  message: string;
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
}

const DialogError: React.FC<IDialogProps> = props => {
  const { message, openDialog, setOpenDialog } = props;

  const handleCloseDialog = (): void => {
    setOpenDialog(false);
  };

  return (
    <Styles.Root>
      <Dialog open={openDialog} maxWidth="xs" onClose={handleCloseDialog}>
        <DialogTitle>
          <Typography>Error</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Styles.Root>
  );
};

export default DialogError;
