import { Color } from '@mui/material';
import { esES } from '@mui/material/locale';
import { CommonColors, createTheme, PaletteColor, PaletteColorOptions } from '@mui/material/styles';
import {
  ColorPartial,
  PaletteAugmentColorOptions,
  PaletteTonalOffset,
  TypeAction,
  TypeBackground,
  TypeDivider,
  TypeText,
} from '@mui/material/styles/createPalette';
import { primary, secondary } from './palettes';

const theme = createTheme(
  {
    palette: {
      'gi-primary': {
        main: primary[400],
      },
      'gi-secondary': {
        main: secondary[900],
      },
    },
  },
  esES,
);

declare module '@mui/material/styles' {
  type PaletteMode = 'light' | 'dark';
  interface Theme {
    palette: {
      action: TypeAction;
      background: TypeBackground;
      common: CommonColors;
      contrastThreshold: number;
      divider: TypeDivider;
      error: PaletteColor;
      grey: Color;
      info: PaletteColor;
      mode: PaletteMode;
      primary: PaletteColor;
      secondary: PaletteColor;
      success: PaletteColor;
      text: TypeText;
      tonalOffset: PaletteTonalOffset;
      warning: PaletteColor;
      'gi-primary': PaletteColor;
      'gi-secondary': PaletteColor;
      augmentColor: (options: PaletteAugmentColorOptions) => PaletteColor;
      getContrastText: (background: string) => string;
    };
  }

  interface PaletteOptions {
    action?: Partial<TypeAction>;
    background?: Partial<TypeBackground>;
    common?: Partial<CommonColors>;
    contrastThreshold?: number;
    divider?: string;
    error?: PaletteColorOptions;
    grey?: ColorPartial;
    info?: PaletteColorOptions;
    mode?: PaletteMode;
    primary?: PaletteColorOptions;
    secondary?: PaletteColorOptions;
    success?: PaletteColorOptions;
    text?: Partial<TypeText>;
    tonalOffset?: PaletteTonalOffset;
    warning?: PaletteColorOptions;
    'gi-primary': PaletteColorOptions;
    'gi-secondary': PaletteColorOptions;
    getContrastText?: (background: string) => string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    'gi-primary': true;
    'gi-secondary': true;
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    'gi-primary': true;
    'gi-secondary': true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'gi-primary': true;
    'gi-secondary': true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    'gi-primary': true;
    'gi-secondary': true;
  }
}

export default theme;
