import React from 'react';
import { Button, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { useLogout, useUser } from '../../modules/amplify';

const ProfileMenu: React.FC = () => {
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);
  const open = React.useMemo(() => Boolean(anchor), [anchor]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const user = useUser();
  const logout = useLogout();
  const handleLogout = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement>) => {
      setAnchor(null);
      logout(event as unknown as React.FormEvent<HTMLFormElement>);
    },
    [logout],
  );
  const handleClose = React.useCallback(() => setAnchor(null), []);
  const handleOpen = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => setAnchor(event.currentTarget),
    [],
  );

  return (
    <>
      <Button
        disableElevation
        disableRipple
        aria-controls={open ? 'menú de usuario' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        color="gi-primary"
        endIcon={<KeyboardArrowDownIcon />}
        variant="text"
        onClick={handleOpen}
      >
        {user.name}
      </Button>
      <Menu
        anchorEl={anchor}
        anchorOrigin={{
          horizontal: matches ? 'center' : 'right',
          vertical: 'bottom',
        }}
        open={open}
        transformOrigin={{
          horizontal: matches ? 'center' : 'right',
          vertical: 'top',
        }}
        onClose={handleClose}
      >
        <MenuItem disableRipple onClick={handleLogout}>
          Cerrar sesión
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
