import { Amplify, Storage } from 'aws-amplify';
import {
  REACT_APP_AWS_COGNITO_USER_POOL_ID,
  REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_S3_BUCKET,
  REACT_AWS_COGNITO_IDENTITY_POOL_ID,
} from '../config/environment';

Amplify.configure({
  Auth: {
    identityPoolId: REACT_AWS_COGNITO_IDENTITY_POOL_ID,
    region: REACT_APP_AWS_REGION,
    userPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: REACT_APP_AWS_S3_BUCKET,
      region: REACT_APP_AWS_REGION,
    },
  },
});

export interface BucketFile {
  id: string;
  key: string;
  lastModified: Date;
  size: number;
}

export const getFiles = async (s3KeyPrefix: string): Promise<BucketFile[]> => {
  const { results } = await Storage.list(`${s3KeyPrefix}`);
  const fileKeys: BucketFile[] =
    results.length > 0
      ? results.map(file => ({
          id: '',
          key: file.key ?? '',
          lastModified: file.lastModified ?? new Date(),
          size: file.size ?? 0,
        }))
      : [];
  return fileKeys;
};

export const uploadFile = async (s3KeyPrefix: string, selectedFile: File): Promise<BucketFile> => {
  const s3Key = `${s3KeyPrefix}/${selectedFile.name}`;
  await Storage.put(s3Key, selectedFile);
  const newBucketFile: BucketFile = {
    id: '',
    key: s3Key,
    lastModified: new Date(selectedFile.lastModified),
    size: selectedFile.size,
  };
  return newBucketFile;
};

export const getSignedURL = async (keyFile: string): Promise<Blob> => {
  const signedURL = await Storage.get(keyFile);
  const result = await fetch(signedURL);
  return await result.blob();
};
