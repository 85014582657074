/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateEntryInput = {
  id?: string | null,
  author: string,
  conversation: string,
  message: string,
  stage?: string | null,
  metadata?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelEntryConditionInput = {
  author?: ModelIDInput | null,
  conversation?: ModelIDInput | null,
  message?: ModelStringInput | null,
  stage?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEntryConditionInput | null > | null,
  or?: Array< ModelEntryConditionInput | null > | null,
  not?: ModelEntryConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Entry = {
  __typename: "Entry",
  id: string,
  author: string,
  conversation: string,
  message: string,
  stage?: string | null,
  metadata?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateEntryInput = {
  id: string,
  author?: string | null,
  conversation?: string | null,
  message?: string | null,
  stage?: string | null,
  metadata?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteEntryInput = {
  id: string,
};

export type CreateDocumentInput = {
  id?: string | null,
  author: string,
  conversation: string,
  content: string,
  stage?: string | null,
  metadata?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelDocumentConditionInput = {
  author?: ModelIDInput | null,
  conversation?: ModelIDInput | null,
  content?: ModelStringInput | null,
  stage?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDocumentConditionInput | null > | null,
  or?: Array< ModelDocumentConditionInput | null > | null,
  not?: ModelDocumentConditionInput | null,
};

export type Document = {
  __typename: "Document",
  id: string,
  author: string,
  conversation: string,
  content: string,
  stage?: string | null,
  metadata?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDocumentInput = {
  id: string,
  author?: string | null,
  conversation?: string | null,
  content?: string | null,
  stage?: string | null,
  metadata?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteDocumentInput = {
  id: string,
};

export type CreateConversationEventInput = {
  author: string,
  conversation: string,
  type: string,
  stage: string,
  createdAt?: string | null,
  id?: string | null,
};

export type ModelConversationEventConditionInput = {
  author?: ModelIDInput | null,
  conversation?: ModelIDInput | null,
  type?: ModelStringInput | null,
  stage?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelConversationEventConditionInput | null > | null,
  or?: Array< ModelConversationEventConditionInput | null > | null,
  not?: ModelConversationEventConditionInput | null,
};

export type ConversationEvent = {
  __typename: "ConversationEvent",
  author: string,
  conversation: string,
  type: string,
  stage: string,
  createdAt: string,
  id: string,
  updatedAt: string,
};

export type UpdateConversationEventInput = {
  author?: string | null,
  conversation?: string | null,
  type?: string | null,
  stage?: string | null,
  createdAt?: string | null,
  id: string,
};

export type DeleteConversationEventInput = {
  id: string,
};

export type CreateConversationEventFinishedInput = {
  author: string,
  conversation: string,
  type: string,
  stage: string,
  createdAt?: string | null,
  id?: string | null,
};

export type ModelConversationEventFinishedConditionInput = {
  author?: ModelIDInput | null,
  conversation?: ModelIDInput | null,
  type?: ModelStringInput | null,
  stage?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelConversationEventFinishedConditionInput | null > | null,
  or?: Array< ModelConversationEventFinishedConditionInput | null > | null,
  not?: ModelConversationEventFinishedConditionInput | null,
};

export type ConversationEventFinished = {
  __typename: "ConversationEventFinished",
  author: string,
  conversation: string,
  type: string,
  stage: string,
  createdAt: string,
  id: string,
  updatedAt: string,
};

export type UpdateConversationEventFinishedInput = {
  author?: string | null,
  conversation?: string | null,
  type?: string | null,
  stage?: string | null,
  createdAt?: string | null,
  id: string,
};

export type DeleteConversationEventFinishedInput = {
  id: string,
};

export type ModelEntryFilterInput = {
  id?: ModelIDInput | null,
  author?: ModelIDInput | null,
  conversation?: ModelIDInput | null,
  message?: ModelStringInput | null,
  stage?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEntryFilterInput | null > | null,
  or?: Array< ModelEntryFilterInput | null > | null,
  not?: ModelEntryFilterInput | null,
};

export type ModelEntryConnection = {
  __typename: "ModelEntryConnection",
  items:  Array<Entry | null >,
  nextToken?: string | null,
};

export type ModelDocumentFilterInput = {
  id?: ModelIDInput | null,
  author?: ModelIDInput | null,
  conversation?: ModelIDInput | null,
  content?: ModelStringInput | null,
  stage?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDocumentFilterInput | null > | null,
  or?: Array< ModelDocumentFilterInput | null > | null,
  not?: ModelDocumentFilterInput | null,
};

export type ModelDocumentConnection = {
  __typename: "ModelDocumentConnection",
  items:  Array<Document | null >,
  nextToken?: string | null,
};

export type ModelConversationEventFilterInput = {
  author?: ModelIDInput | null,
  conversation?: ModelIDInput | null,
  type?: ModelStringInput | null,
  stage?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelConversationEventFilterInput | null > | null,
  or?: Array< ModelConversationEventFilterInput | null > | null,
  not?: ModelConversationEventFilterInput | null,
};

export type ModelConversationEventConnection = {
  __typename: "ModelConversationEventConnection",
  items:  Array<ConversationEvent | null >,
  nextToken?: string | null,
};

export type ModelConversationEventFinishedFilterInput = {
  author?: ModelIDInput | null,
  conversation?: ModelIDInput | null,
  type?: ModelStringInput | null,
  stage?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelConversationEventFinishedFilterInput | null > | null,
  or?: Array< ModelConversationEventFinishedFilterInput | null > | null,
  not?: ModelConversationEventFinishedFilterInput | null,
};

export type ModelConversationEventFinishedConnection = {
  __typename: "ModelConversationEventFinishedConnection",
  items:  Array<ConversationEventFinished | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelSubscriptionEntryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  author?: ModelSubscriptionIDInput | null,
  conversation?: ModelSubscriptionIDInput | null,
  message?: ModelSubscriptionStringInput | null,
  stage?: ModelSubscriptionStringInput | null,
  metadata?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEntryFilterInput | null > | null,
  or?: Array< ModelSubscriptionEntryFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionDocumentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  author?: ModelSubscriptionIDInput | null,
  conversation?: ModelSubscriptionIDInput | null,
  content?: ModelSubscriptionStringInput | null,
  stage?: ModelSubscriptionStringInput | null,
  metadata?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDocumentFilterInput | null > | null,
  or?: Array< ModelSubscriptionDocumentFilterInput | null > | null,
};

export type ModelSubscriptionConversationEventFilterInput = {
  author?: ModelSubscriptionIDInput | null,
  conversation?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  stage?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConversationEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionConversationEventFilterInput | null > | null,
};

export type ModelSubscriptionConversationEventFinishedFilterInput = {
  author?: ModelSubscriptionIDInput | null,
  conversation?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  stage?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConversationEventFinishedFilterInput | null > | null,
  or?: Array< ModelSubscriptionConversationEventFinishedFilterInput | null > | null,
};

export type CreateEntryMutationVariables = {
  input: CreateEntryInput,
  condition?: ModelEntryConditionInput | null,
};

export type CreateEntryMutation = {
  createEntry?:  {
    __typename: "Entry",
    id: string,
    author: string,
    conversation: string,
    message: string,
    stage?: string | null,
    metadata?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEntryMutationVariables = {
  input: UpdateEntryInput,
  condition?: ModelEntryConditionInput | null,
};

export type UpdateEntryMutation = {
  updateEntry?:  {
    __typename: "Entry",
    id: string,
    author: string,
    conversation: string,
    message: string,
    stage?: string | null,
    metadata?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEntryMutationVariables = {
  input: DeleteEntryInput,
  condition?: ModelEntryConditionInput | null,
};

export type DeleteEntryMutation = {
  deleteEntry?:  {
    __typename: "Entry",
    id: string,
    author: string,
    conversation: string,
    message: string,
    stage?: string | null,
    metadata?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDocumentMutationVariables = {
  input: CreateDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type CreateDocumentMutation = {
  createDocument?:  {
    __typename: "Document",
    id: string,
    author: string,
    conversation: string,
    content: string,
    stage?: string | null,
    metadata?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDocumentMutationVariables = {
  input: UpdateDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type UpdateDocumentMutation = {
  updateDocument?:  {
    __typename: "Document",
    id: string,
    author: string,
    conversation: string,
    content: string,
    stage?: string | null,
    metadata?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDocumentMutationVariables = {
  input: DeleteDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type DeleteDocumentMutation = {
  deleteDocument?:  {
    __typename: "Document",
    id: string,
    author: string,
    conversation: string,
    content: string,
    stage?: string | null,
    metadata?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateConversationEventMutationVariables = {
  input: CreateConversationEventInput,
  condition?: ModelConversationEventConditionInput | null,
};

export type CreateConversationEventMutation = {
  createConversationEvent?:  {
    __typename: "ConversationEvent",
    author: string,
    conversation: string,
    type: string,
    stage: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type UpdateConversationEventMutationVariables = {
  input: UpdateConversationEventInput,
  condition?: ModelConversationEventConditionInput | null,
};

export type UpdateConversationEventMutation = {
  updateConversationEvent?:  {
    __typename: "ConversationEvent",
    author: string,
    conversation: string,
    type: string,
    stage: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type DeleteConversationEventMutationVariables = {
  input: DeleteConversationEventInput,
  condition?: ModelConversationEventConditionInput | null,
};

export type DeleteConversationEventMutation = {
  deleteConversationEvent?:  {
    __typename: "ConversationEvent",
    author: string,
    conversation: string,
    type: string,
    stage: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type CreateConversationEventFinishedMutationVariables = {
  input: CreateConversationEventFinishedInput,
  condition?: ModelConversationEventFinishedConditionInput | null,
};

export type CreateConversationEventFinishedMutation = {
  createConversationEventFinished?:  {
    __typename: "ConversationEventFinished",
    author: string,
    conversation: string,
    type: string,
    stage: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type UpdateConversationEventFinishedMutationVariables = {
  input: UpdateConversationEventFinishedInput,
  condition?: ModelConversationEventFinishedConditionInput | null,
};

export type UpdateConversationEventFinishedMutation = {
  updateConversationEventFinished?:  {
    __typename: "ConversationEventFinished",
    author: string,
    conversation: string,
    type: string,
    stage: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type DeleteConversationEventFinishedMutationVariables = {
  input: DeleteConversationEventFinishedInput,
  condition?: ModelConversationEventFinishedConditionInput | null,
};

export type DeleteConversationEventFinishedMutation = {
  deleteConversationEventFinished?:  {
    __typename: "ConversationEventFinished",
    author: string,
    conversation: string,
    type: string,
    stage: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type GetEntryQueryVariables = {
  id: string,
};

export type GetEntryQuery = {
  getEntry?:  {
    __typename: "Entry",
    id: string,
    author: string,
    conversation: string,
    message: string,
    stage?: string | null,
    metadata?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEntriesQueryVariables = {
  filter?: ModelEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEntriesQuery = {
  listEntries?:  {
    __typename: "ModelEntryConnection",
    items:  Array< {
      __typename: "Entry",
      id: string,
      author: string,
      conversation: string,
      message: string,
      stage?: string | null,
      metadata?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDocumentQueryVariables = {
  id: string,
};

export type GetDocumentQuery = {
  getDocument?:  {
    __typename: "Document",
    id: string,
    author: string,
    conversation: string,
    content: string,
    stage?: string | null,
    metadata?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDocumentsQueryVariables = {
  filter?: ModelDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentsQuery = {
  listDocuments?:  {
    __typename: "ModelDocumentConnection",
    items:  Array< {
      __typename: "Document",
      id: string,
      author: string,
      conversation: string,
      content: string,
      stage?: string | null,
      metadata?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetConversationEventQueryVariables = {
  id: string,
};

export type GetConversationEventQuery = {
  getConversationEvent?:  {
    __typename: "ConversationEvent",
    author: string,
    conversation: string,
    type: string,
    stage: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type ListConversationEventsQueryVariables = {
  filter?: ModelConversationEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConversationEventsQuery = {
  listConversationEvents?:  {
    __typename: "ModelConversationEventConnection",
    items:  Array< {
      __typename: "ConversationEvent",
      author: string,
      conversation: string,
      type: string,
      stage: string,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetConversationEventFinishedQueryVariables = {
  id: string,
};

export type GetConversationEventFinishedQuery = {
  getConversationEventFinished?:  {
    __typename: "ConversationEventFinished",
    author: string,
    conversation: string,
    type: string,
    stage: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type ListConversationEventFinishedsQueryVariables = {
  filter?: ModelConversationEventFinishedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConversationEventFinishedsQuery = {
  listConversationEventFinisheds?:  {
    __typename: "ModelConversationEventFinishedConnection",
    items:  Array< {
      __typename: "ConversationEventFinished",
      author: string,
      conversation: string,
      type: string,
      stage: string,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EntriesByCreatedAtQueryVariables = {
  conversation: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EntriesByCreatedAtQuery = {
  entriesByCreatedAt?:  {
    __typename: "ModelEntryConnection",
    items:  Array< {
      __typename: "Entry",
      id: string,
      author: string,
      conversation: string,
      message: string,
      stage?: string | null,
      metadata?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DocumentsByCreatedAtQueryVariables = {
  conversation: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DocumentsByCreatedAtQuery = {
  documentsByCreatedAt?:  {
    __typename: "ModelDocumentConnection",
    items:  Array< {
      __typename: "Document",
      id: string,
      author: string,
      conversation: string,
      content: string,
      stage?: string | null,
      metadata?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ConversationEventByCreatedAtQueryVariables = {
  conversation: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConversationEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConversationEventByCreatedAtQuery = {
  conversationEventByCreatedAt?:  {
    __typename: "ModelConversationEventConnection",
    items:  Array< {
      __typename: "ConversationEvent",
      author: string,
      conversation: string,
      type: string,
      stage: string,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ConversationEventFinishedByCreatedAtQueryVariables = {
  conversation: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConversationEventFinishedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConversationEventFinishedByCreatedAtQuery = {
  conversationEventFinishedByCreatedAt?:  {
    __typename: "ModelConversationEventFinishedConnection",
    items:  Array< {
      __typename: "ConversationEventFinished",
      author: string,
      conversation: string,
      type: string,
      stage: string,
      createdAt: string,
      id: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateEntrySubscriptionVariables = {
  filter?: ModelSubscriptionEntryFilterInput | null,
};

export type OnCreateEntrySubscription = {
  onCreateEntry?:  {
    __typename: "Entry",
    id: string,
    author: string,
    conversation: string,
    message: string,
    stage?: string | null,
    metadata?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEntrySubscriptionVariables = {
  filter?: ModelSubscriptionEntryFilterInput | null,
};

export type OnUpdateEntrySubscription = {
  onUpdateEntry?:  {
    __typename: "Entry",
    id: string,
    author: string,
    conversation: string,
    message: string,
    stage?: string | null,
    metadata?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEntrySubscriptionVariables = {
  filter?: ModelSubscriptionEntryFilterInput | null,
};

export type OnDeleteEntrySubscription = {
  onDeleteEntry?:  {
    __typename: "Entry",
    id: string,
    author: string,
    conversation: string,
    message: string,
    stage?: string | null,
    metadata?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFilterInput | null,
};

export type OnCreateDocumentSubscription = {
  onCreateDocument?:  {
    __typename: "Document",
    id: string,
    author: string,
    conversation: string,
    content: string,
    stage?: string | null,
    metadata?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFilterInput | null,
};

export type OnUpdateDocumentSubscription = {
  onUpdateDocument?:  {
    __typename: "Document",
    id: string,
    author: string,
    conversation: string,
    content: string,
    stage?: string | null,
    metadata?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFilterInput | null,
};

export type OnDeleteDocumentSubscription = {
  onDeleteDocument?:  {
    __typename: "Document",
    id: string,
    author: string,
    conversation: string,
    content: string,
    stage?: string | null,
    metadata?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateConversationEventSubscriptionVariables = {
  filter?: ModelSubscriptionConversationEventFilterInput | null,
};

export type OnCreateConversationEventSubscription = {
  onCreateConversationEvent?:  {
    __typename: "ConversationEvent",
    author: string,
    conversation: string,
    type: string,
    stage: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateConversationEventSubscriptionVariables = {
  filter?: ModelSubscriptionConversationEventFilterInput | null,
};

export type OnUpdateConversationEventSubscription = {
  onUpdateConversationEvent?:  {
    __typename: "ConversationEvent",
    author: string,
    conversation: string,
    type: string,
    stage: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteConversationEventSubscriptionVariables = {
  filter?: ModelSubscriptionConversationEventFilterInput | null,
};

export type OnDeleteConversationEventSubscription = {
  onDeleteConversationEvent?:  {
    __typename: "ConversationEvent",
    author: string,
    conversation: string,
    type: string,
    stage: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type OnCreateConversationEventFinishedSubscriptionVariables = {
  filter?: ModelSubscriptionConversationEventFinishedFilterInput | null,
};

export type OnCreateConversationEventFinishedSubscription = {
  onCreateConversationEventFinished?:  {
    __typename: "ConversationEventFinished",
    author: string,
    conversation: string,
    type: string,
    stage: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateConversationEventFinishedSubscriptionVariables = {
  filter?: ModelSubscriptionConversationEventFinishedFilterInput | null,
};

export type OnUpdateConversationEventFinishedSubscription = {
  onUpdateConversationEventFinished?:  {
    __typename: "ConversationEventFinished",
    author: string,
    conversation: string,
    type: string,
    stage: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteConversationEventFinishedSubscriptionVariables = {
  filter?: ModelSubscriptionConversationEventFinishedFilterInput | null,
};

export type OnDeleteConversationEventFinishedSubscription = {
  onDeleteConversationEventFinished?:  {
    __typename: "ConversationEventFinished",
    author: string,
    conversation: string,
    type: string,
    stage: string,
    createdAt: string,
    id: string,
    updatedAt: string,
  } | null,
};
