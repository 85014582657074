import { styled } from '@mui/material';
import * as Common from '../Styles/Common.styles';

export const Root = styled(Common.Root)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

export const Image = styled('img')`
  cursor: pointer;
`;

export const Footer = styled(Common.GridContainer)`
  &.MuiGrid-container {
    background-color: ${props => props.theme.palette['gi-secondary'].main};
  }
`;
