import cookies from 'js-cookie';
import { REACT_APP_API_BASE_URL } from '../config/environment';
import { ACCESS_TOKEN_COOKIE_KEY } from '../modules/amplify/auth';
export interface Person {
  abbreviation: string;
  email: string;
  id: string;
  key: string;
  name: string;
  roleId: string;
}

export interface PersonHome {
  id: string;
  name: string;
  abbreviation: string;
  email: string;
  roleId: string;
}

export const getPeople = async (): Promise<Person[]> => {
  const response = await fetch(`${REACT_APP_API_BASE_URL}/people`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get(ACCESS_TOKEN_COOKIE_KEY) ?? ''}`,
    },
  });
  return await response.json();
};
